import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'
import colors from '../../css/colors.module.css'

export interface LoadingProps {
  className?: string
}

export const Loading: React.FC<LoadingProps> = ({ className = '' }) => {
  return (
    <div aria-label="Loading" className={classnames(styles.loading, className)}>
      <svg
        className={styles.loading__svg}
        stroke={colors['primary-green']}
        viewBox="0 0 38 38"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" strokeWidth="2" transform="translate(1 1)">
          <circle cx="18" cy="18" r="18" strokeOpacity=".5" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              dur="1s"
              from="0 18 18"
              repeatCount="indefinite"
              to="360 18 18"
              type="rotate"
            />
          </path>
        </g>
      </svg>
    </div>
  )
}

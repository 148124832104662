import React from 'react'
import { Omit } from 'react-router'
import styles from './styles.module.css'

const remainingCount = (lengthLimit: number, value: string) => {
  return lengthLimit - value.length
}

const renderRemainingCount = (
  lengthLimit: number | undefined,
  value: string
) => {
  if (lengthLimit) {
    return (
      <label className={styles.counter}>
        <span className={styles.counter__text}>
          {remainingCount(lengthLimit, value)}
        </span>
      </label>
    )
  }

  return ''
}

export interface Props
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  disabled?: boolean
  error?: boolean
  lengthLimit?: number
  onChange?: (value: string) => void
  placeholder?: string
  value?: string
}

// TODO: The current implementation is not compatible with Formik. Either
// call onChange with the raw Event, or call it with the field's name and
// value.
export const TextArea: React.FC<Props> = ({
  disabled = false,
  error = false,
  lengthLimit,
  onChange = () => {},
  placeholder = '',
  value = '',
  ...rest
}) => {
  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!lengthLimit || remainingCount(lengthLimit, evt.target.value) >= 0) {
      onChange(evt.target.value)
    }
  }

  const textAreaStyles = [styles.textarea]

  if (error) {
    textAreaStyles.push(styles.error)
  }

  return (
    <div className={styles.container}>
      <textarea
        className={textAreaStyles.join(' ')}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        {...rest}
      />
      {renderRemainingCount(lengthLimit, value)}
    </div>
  )
}

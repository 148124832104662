import React from 'react'
import { format } from 'date-fns'
import styles from './styles.module.css'
import { NoFilters } from './NoFilters'
import { Icon } from '../Icon'
import { Chip } from '../Chip'
import { SelectedFilter, Option } from './Filter.interface'
import { GridRow } from '../GridRow'
import { Button } from '../Button'

export interface Props {
  selectedFilter: SelectedFilter
  onToggle: () => void
  onRefresh?: () => void
  isHidden?: boolean
  removeFilter: (index: number, type: keyof SelectedFilter) => void
  lastRefresh?: Date
}

// 3:01am
const REFRESHED_FORMAT = `h:mmaaaaa'm'`

export const Filters: React.FC<Props> = props => {
  const {
    isHidden,
    selectedFilter,
    onToggle,
    onRefresh,
    removeFilter,
    lastRefresh = new Date(Date.now())
  } = props

  const doRefresh = () => {
    if (onRefresh) {
      onRefresh()
    }
  }

  const filterView = (
    index: number,
    data: Option,
    type: keyof SelectedFilter
  ) => (
    <div className={styles.chipDiv} key={`value${type}${data.label}`}>
      <Chip
        onDismiss={() => removeFilter(index, type)}
        text={data.label}
        variant="default"
      />
    </div>
  )

  const chips = () => {
    return (
      isHidden && (
        <>
          {selectedFilter.locations &&
            selectedFilter.locations.map((data, index) =>
              filterView(index, data, 'locations')
            )}
          {selectedFilter.providers &&
            selectedFilter.providers.map((data, index) =>
              filterView(index, data, 'providers')
            )}
          {selectedFilter.population &&
            filterView(0, selectedFilter.population, 'population')}
          {selectedFilter.therapies &&
            selectedFilter.therapies.map((data, index) =>
              filterView(index, data, 'therapies')
            )}
        </>
      )
    )
  }

  return (
    <GridRow className={styles.barRow}>
      <div className={styles.bar}>
        <div className={styles.bar__expand}>
          <Button variant="tertiary" onClick={onToggle}>
            <Icon glyph={isHidden ? 'chevron-down' : 'chevron-up'} />
            &nbsp;Filters
          </Button>
        </div>
        <div className={styles.bar__chips}>
          {Object.entries(selectedFilter).length === 0 ? (
            <NoFilters />
          ) : (
            chips()
          )}
        </div>
        <div className={styles.bar__refresh}>
          <Button variant="tertiary" onClick={doRefresh}>
            <Icon glyph="sync" />
            <span className={styles.bar__refreshText}>
              &nbsp;Last refresh {format(lastRefresh, REFRESHED_FORMAT)}
            </span>
          </Button>
        </div>
      </div>
    </GridRow>
  )
}

export default Filters

Filters.defaultProps = {
  selectedFilter: {}
}

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'
import { Button } from '../Button'
import { Icon } from '../Icon'

const escapeKeyCode = 27

export interface Props {
  className?: string
  onCloseAttempt: () => void
  title?: string
}

export class Modal extends React.Component<Props> {
  public static defaultProps = {
    onCloseAttempt: () => {}
  }

  public componentDidMount() {
    const { onCloseAttempt } = this.props
    document.addEventListener('keydown', e => {
      if (e.keyCode === escapeKeyCode && !e.defaultPrevented) {
        onCloseAttempt()
      }
    })
  }

  public componentWillUnmount() {
    const { onCloseAttempt } = this.props
    document.removeEventListener('keydown', e => {
      if (e.keyCode === escapeKeyCode && !e.defaultPrevented) {
        onCloseAttempt()
      }
    })
  }

  public render() {
    const { children, className, onCloseAttempt, title } = this.props

    return (
      <div className={styles.modal}>
        <div className={styles.sneezeguard} />
        <div className={classNames(styles.modalContainer, className)}>
          {title && <header className={styles.modalTitle}>{title}</header>}
          <div className={styles.close}>
            <Button variant="tertiary" onClick={onCloseAttempt} flush>
              <span className={styles.icon}>
                <Icon glyph="times" />
              </span>
            </Button>
          </div>
          <div>{children}</div>
        </div>
      </div>
    )
  }
}

import React from 'react'
import { withFormik, FormikProps } from 'formik'
import { GridColumn } from '../GridColumn'
import { GridRow } from '../GridRow'
import { SelectField } from '../SelectField'
import { Button } from '../Button'
import { FilterOption, SelectedFilterValues } from './Filter.interface'
import styles from './styles.module.css'

const defaultValues = () => ({
  providers: [],
  locations: [],
  population: '',
  therapies: []
})

export interface Props {
  showPopulationFilter?: boolean
  showProviderFilter?: boolean
  showLocationFilter?: boolean
  showTherapyFilter?: boolean
  onClearAll?: (values: SelectedFilterValues) => void
  togglePanel: () => void
  filterOptions: FilterOption
  filterValues: SelectedFilterValues
  onSubmit: (values: SelectedFilterValues) => void
}

export declare type InnerProps = Props & FormikProps<any>

const FilterOptions: React.FC<InnerProps> = (props: InnerProps) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    filterOptions,
    showPopulationFilter,
    showProviderFilter,
    showLocationFilter,
    showTherapyFilter,
    resetForm,
    onClearAll,
    onSubmit
  } = props
  const { providerOptions, locationOptions, populationOptions, therapyOptions } = filterOptions

  const clearAll = () => {
    const resetValues = defaultValues()
    resetForm(resetValues)
    if (onClearAll) {
      onClearAll(resetValues)
    }
    onSubmit(resetValues)
  }

  return (
    <>
      <GridRow className={styles.filterRow}>
        <GridColumn span={6}>
          {showLocationFilter && (
            <>
              <label className={styles.filterLabel}>Locations</label>
              <SelectField
                isMulti
                name="locations"
                onChange={setFieldValue}
                placeholder="Select"
                options={locationOptions}
                currentValue={values.locations}
                closeMenuOnSelect={false}
              />
            </>
          )}
          {showProviderFilter && (
            <>
              <label className={styles.filterLabel}>Providers</label>
              <SelectField
                isMulti
                name="providers"
                onChange={setFieldValue}
                placeholder="Select"
                options={providerOptions}
                currentValue={values.providers}
                closeMenuOnSelect={false}
              />
            </>
          )}
        </GridColumn>
        <GridColumn span={6}>
          {showPopulationFilter && (
            <>
              <label className={styles.filterLabel}>Populations</label>
              <SelectField
                isMulti={false}
                name="population"
                onChange={setFieldValue}
                options={populationOptions}
                placeholder="Select"
                currentValue={values.population}
              />
            </>
          )}
          {showTherapyFilter && (
            <>
              <label className={styles.filterLabel}>Treatment</label>
              <SelectField
                isMulti
                name="therapies"
                onChange={setFieldValue}
                placeholder="Select"
                options={therapyOptions}
                currentValue={values.therapies}
                closeMenuOnSelect={false}
              />
            </>
          )}
        </GridColumn>
      </GridRow>
      <div className={styles.filterRowSpacer} />
      <GridRow className={styles.buttonRow}>
        <GridColumn span={6} />
        <GridColumn span={3}>
          <Button
            className={styles.buttonRow__button}
            variant="secondary"
            onClick={clearAll}
          >
            Clear All
          </Button>
        </GridColumn>
        <GridColumn span={3}>
          <Button
            variant="primary"
            className={styles.buttonRow__button}
            onClick={(event: any) => handleSubmit(event)}
          >
            Apply Filters
          </Button>
        </GridColumn>
      </GridRow>
    </>
  )
}

export default withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: (props: InnerProps) => ({
    providers: props.filterValues.providers || [],
    locations: props.filterValues.locations || [],
    population: props.filterValues.population || '',
    therapies: props.filterValues.therapies || []
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
})(FilterOptions)

import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'

type OnChange = (name: string, checked: boolean) => void

export interface CheckboxProps {
  checked?: boolean
  className?: string
  indeterminate?: boolean
  disabled?: boolean
  name: string
  onChange?: OnChange
  renderInline?: boolean
  text?: string
  value?: string
}

const handleChange = (checked: boolean, name: string, onChange: OnChange) => {
  onChange(name, !checked)
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  className = '',
  indeterminate = false,
  disabled,
  name,
  onChange = () => {},
  renderInline,
  text,
  value = ''
}) => {
  const id = `nc-checkbox-${name}${Date.now()}`
  const onClick = (): void => handleChange(checked, name, onChange)
  const onKeyDown = (event: React.KeyboardEvent): void => {
    if (event.keyCode === 13) handleChange(checked, name, onChange)
  }

  return (
    <div
      className={classnames(
        styles.container,
        renderInline && 'inline-block',
        className
      )}
    >
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        className={styles.checkbox}
        checked={checked}
        readOnly
        disabled={disabled}
        ref={(input: HTMLInputElement) => {
          const inputRef: HTMLInputElement = input
          if (input) {
            inputRef.indeterminate = indeterminate
          }
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
      />
      <label className={styles.label} htmlFor={id}>
        {text}
      </label>
    </div>
  )
}

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

type Variant = 'default' | 'meta' | 'supporting' | 'label'

export interface Props {
  className?: string
  variant?: Variant
}

export const Paragraph: React.FC<Props> = ({
  children,
  className,
  variant
}) => (
  <p
    className={classNames(
      styles.paragraph,
      styles[`paragraph--${variant}`],
      className
    )}
  >
    {children}
  </p>
)

Paragraph.defaultProps = {
  variant: 'default'
}

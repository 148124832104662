import React from 'react'
import { RadioButton } from '../RadioButton'
import styles from './styles.module.css'

export interface RadioGroupProps {
  name: string
  disabled?: boolean
  selectedOption?: string
  separateColumns?: boolean
  renderInline?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  options: Option[]
}

export interface Option {
  label: string
  id: string
  value: string
}

const renderRadioGroup = (
  options: Option[],
  {
    disabled,
    onChange,
    name,
    renderInline,
    selectedOption
  }: { [key: string]: any }
) =>
  options.map(({ value, id, label }) => (
    <RadioButton
      key={name + value}
      name={name}
      label={label}
      id={id}
      value={value}
      checkedValue={selectedOption}
      disabled={disabled}
      handleChange={onChange}
      renderInline={renderInline}
    />
  ))

const splitHalf = (options: Option[]) => [
  options.slice(0, Math.ceil(options.length / 2)),
  options.slice(Math.ceil(options.length / 2))
]

export const RadioGroup: React.FC<RadioGroupProps> = ({
  selectedOption,
  disabled,
  onChange = () => {},
  name,
  renderInline,
  options,
  separateColumns
}) => {
  const radioGroupProps = {
    selectedOption,
    disabled,
    onChange,
    name,
    renderInline
  }
  if (separateColumns) {
    return (
      <>
        <div className={styles.columns}>
          {renderRadioGroup(splitHalf(options)[0], radioGroupProps)}
        </div>
        <div className={styles.columns}>
          {renderRadioGroup(splitHalf(options)[1], radioGroupProps)}
        </div>
      </>
    )
  }
  return <>{renderRadioGroup(options, radioGroupProps)}</>
}

import colors from '../css/colors.module.css'
import spacing from '../css/spacing.module.css'

// Do want something flat like this, or something more nested
// Styles.margin.bottom.0
// Styles.color.primaryGreen
// Styles.backgroundColor.veryLightGreen
export const Styles = {
  backgroundColorBlack: colors['background-color-black'],
  backgroundColorDarkGrey: colors['background-color-dark-grey'],
  backgroundColorGrey: colors['background-color-grey'],
  backgroundColorLightGrey: colors['background-color-light-grey'],
  backgroundColorVeryLightGrey: colors['background-color-very-light-grey'],
  backgroundColorOffWhite: colors['background-color-off-white'],
  backgroundColorWhite: colors['background-color-white'],
  backgroundColorDarkGreen: colors['background-color-dark-green'],
  backgroundColorPrimaryGreen: colors['background-color-primary-green'],
  backgroundColorMediumGreen: colors['background-color-medium-green'],
  backgroundColorLightGreen: colors['background-color-light-green'],
  backgroundColorVeryLightGreen: colors['background-color-very-light-green'],
  backgroundColorRed: colors['background-color-red'],
  backgroundColorPurple: colors['background-color-purple'],
  backgroundColorPaleGreen: colors['background-color-pale-green'],
  colorBlack: colors['color-black'],
  colorDarkGrey: colors['color-dark-grey'],
  colorGrey: colors['color-grey'],
  colorLightGrey: colors['color-light-grey'],
  colorVeryLightGrey: colors['color-very-light-grey'],
  colorOffWhite: colors['color-off-white'],
  colorWhite: colors['color-white'],
  colorDarkGreen: colors['color-dark-green'],
  colorPrimaryGreen: colors['color-primary-green'],
  colorMediumGreen: colors['color-medium-green'],
  colorLightGreen: colors['color-light-green'],
  colorVeryLightGreen: colors['color-very-light-green'],
  colorRed: colors['color-red'],
  colorPurple: colors['color-purple'],
  colorPaleGreen: colors['color-pale-green'],
  marginBottomlvl0: spacing['margin-bottom-lvl0'],
  marginBottomlvl1: spacing['margin-bottom-lvl1'],
  marginBottomlvl2: spacing['margin-bottom-lvl2'],
  marginBottomlvl3: spacing['margin-bottom-lvl3'],
  marginBottomlvl4: spacing['margin-bottom-lvl4'],
  marginBottomlvl5: spacing['margin-bottom-lvl5'],
  marginTopLvl0: spacing['margin-top-lvl0'],
  marginTopLvl1: spacing['margin-top-lvl1'],
  marginTopLvl2: spacing['margin-top-lvl2'],
  marginTopLvl3: spacing['margin-top-lvl3'],
  marginTopLvl4: spacing['margin-top-lvl4'],
  marginTopLvl5: spacing['margin-top-lvl5']
}

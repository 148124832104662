import React from 'react'
import TooltipTrigger from 'react-popper-tooltip'
import PopperJS from 'popper.js'
import styles from './styles.module.css'

export declare type TriggerTypes =
  | 'none'
  | 'click'
  | 'right-click'
  | 'hover'
  | 'focus'
export declare type Trigger = TriggerTypes | TriggerTypes[]

export interface TooltipProps {
  className?: string
  tooltip?: string
  children?: React.ReactNode
  tooltipPlacement?: PopperJS.Placement
  trigger?: Trigger
  portalContainer?: HTMLElement
  tooltipShown?: boolean
  setTooltipShown?: (value: boolean) => void
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  tooltip,
  className,
  tooltipPlacement,
  trigger,
  portalContainer = document.body,
  tooltipShown,
  setTooltipShown
}) => {
  const containerClass = styles.tooltipContainer
  const arrowClass = styles.tooltipArrow

  return (
    <TooltipTrigger
      placement={tooltipPlacement}
      trigger={trigger}
      portalContainer={portalContainer}
      tooltipShown={tooltipShown}
      onVisibilityChange={setTooltipShown}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: containerClass
          })}
        >
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: arrowClass,
              'data-placement': placement
            })}
          />
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  )
}

import React from 'react'
import styles from './styles.module.css'

export interface FooterProps {
  firstRow: Record<string, string>
  secondRow: Record<string, string>
}

export const Footer: React.FC<FooterProps> = ({ firstRow, secondRow }) => {
  const firstRowList = Object.entries(firstRow).map(([title, link]) => {
    return (
      <a key={title} href={link}>
        {title}
      </a>
    )
  })
  const secondRowList = Object.entries(secondRow).map(([title, link]) => {
    return (
      <a key={title} href={link}>
        {title}
      </a>
    )
  })
  return (
    <footer className={styles.footer}>
      <div className={styles.footer_nav}>
        <div className={styles.footer_sub_nav}>
          <p>Navigating Care</p>
          {firstRowList}
        </div>
        <p className={styles.copyright}>&copy; 2020 Navigating Cancer, Inc.</p>
        <div className={styles.footer_bottom_links}>{secondRowList}</div>
      </div>
    </footer>
  )
}

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

export interface Props {
  children?: React.ReactNode
  colspan?: number
  rowspan?: number
  width?: number
  className?: string
}

export const TableCell: React.FC<Props> = ({
  children,
  colspan,
  rowspan,
  width,
  className
}) => {
  const classes = [styles.table_cell, className]

  return (
    <td
      className={classNames(classes)}
      colSpan={colspan}
      rowSpan={rowspan}
      style={{ width }}
    >
      {typeof children === 'string' ? (
        <span className={styles.table_cell__text_content}>{children}</span>
      ) : (
        children
      )}
    </td>
  )
}

TableCell.defaultProps = {
  colspan: 1,
  rowspan: 1
}

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

export type HeadingLevel = 1 | 2 | 3 | 4 | 5

export interface HeadingProps {
  className?: string
  level: HeadingLevel
}

export const Heading: React.FC<HeadingProps> = ({
  level,
  children,
  className
}) => {
  return React.createElement(
    `h${level}`,
    {
      className: classNames(
        styles.heading,
        styles[`heading_h${level}`],
        className
      )
    },
    children
  )
}

import React from 'react'
import { AlertNotice, InfoNotice, SuccessNotice } from '../Notice'

export type Type = 'alert' | 'error' | 'info' | 'success'

export interface Props {
  text: string
  type: Type
}

const NoticeTypeByType = {
  alert: AlertNotice,
  error: AlertNotice,
  info: InfoNotice,
  success: SuccessNotice
}

export const FormFooterNotice: React.FC<Props> = ({ text, type }) => {
  const NoticeType = NoticeTypeByType[type]
  return <NoticeType text={text} />
}

import React from 'react'
import { Omit } from 'react-router'
import { TextInput, TextInputProps } from '../TextInput'
import { formatPhoneNumber } from '../../utilities/formatPhoneNumber'

export interface Props extends Omit<TextInputProps, 'onChange'> {
  disabled?: boolean
  error?: boolean
  name?: string
  onChange: (newValue: string) => void
  placeholder?: string
  value?: string
}

const shouldInsertDash = (value: string) => /^(\d{4}|\d{3}-\d{4})$/.test(value)
const validPhoneNumber = (value: string) =>
  /^(\d{0,3}|\d{3}-\d{0,3}|\d{3}-\d{3}-\d{0,4})$/.test(value)

export const PhoneInput: React.FC<Props> = ({
  onChange,
  value = '',
  error = false,
  ...rest
}) => {
  const handleChange = (evt: React.FormEvent<HTMLInputElement>) => {
    let newValue = (evt.target as HTMLInputElement).value

    if (shouldInsertDash(newValue)) {
      newValue = `${newValue.slice(0, -1)}-${newValue.slice(-1)}`
    }

    if (!validPhoneNumber(newValue)) return

    newValue = newValue.replace(/-/g, '')
    onChange(newValue)
  }

  return (
    <TextInput
      {...rest}
      onChange={handleChange}
      value={formatPhoneNumber(value)}
      error={error}
    />
  )
}

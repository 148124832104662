import React from 'react'
import { Icon } from '../Icon'
import { Button } from '../Button'
import styles from './styles.module.css'

export interface Props extends React.HTMLAttributes<HTMLDivElement>{
  onDismiss?: () => void
  glyph?: string
}

export const Notification: React.FC<Props> = ({
  children,
  onDismiss,
  glyph = 'check-circle',
  className,
}) => (
  <div className={[styles.notification, className].join(' ')}>
    <Icon glyph={glyph} align="left" />
    <span className={styles.notification__content}>{children}</span>
    {onDismiss && (
      <div className={styles.notification__dismiss}>
        <Button variant="neutral" onClick={onDismiss}>
          <Icon glyph="times" />
        </Button>
      </div>
    )}
  </div>
)

import React from 'react'
import styles from './styles.module.css'
import { Button } from '../Button'
import { GridRow } from '../GridRow'
import { GridColumn } from '../GridColumn'
import { Heading } from '../Heading'
import { Modal } from '../Modal'

export interface Props {
  cancelButtonText?: string
  confirmButtonText?: string
  onCloseAttempt: () => void
  onConfirmAttempt: () => void
  title?: string
}

export const ConfirmationModal: React.FC<Props> = ({
  cancelButtonText,
  children,
  confirmButtonText,
  onCloseAttempt,
  onConfirmAttempt,
  title
}) => {
  return (
    <div className={styles.confirmation_modal}>
      <Modal
        className={styles.confirmation_modal__modal}
        onCloseAttempt={() => onCloseAttempt()}
      >
        {title && (
          <Heading level={2} className={styles.confirmation_modal__title}>
            {title}
          </Heading>
        )}

        {children && (
          <GridRow className={styles.confirmation_modal__content}>
            <GridColumn span={12}>{children}</GridColumn>
          </GridRow>
        )}

        <GridRow className={styles.confirmation_modal__content}>
          <GridColumn span={2}>&nbsp;</GridColumn>
          <GridColumn span={2}>
            <Button variant="tertiary" onClick={() => onCloseAttempt()}>
              {cancelButtonText}
            </Button>
          </GridColumn>
          <GridColumn span={6}>
            <Button
              variant="primary"
              className={styles.confirmation_modal__confirm_button}
              onClick={() => onConfirmAttempt()}
            >
              {confirmButtonText}
            </Button>
          </GridColumn>
          <GridColumn span={2}>&nbsp;</GridColumn>
        </GridRow>
      </Modal>
    </div>
  )
}

ConfirmationModal.defaultProps = {
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  title: 'Confirm?'
}

import React from 'react'
import { Icon, GlyphType, GlyphAlign } from '../Icon'
import styles from './styles.module.css'

const remainingCount = (lengthLimit: number, value: string) => {
  return lengthLimit - value.length
}

const renderRemainingCount = (
  lengthLimit: number | undefined,
  value: string
) => {
  if (lengthLimit) {
    return (
      <label className={styles.counter}>
        <span className={styles.counter__text}>
          {remainingCount(lengthLimit, value)}
        </span>
      </label>
    )
  }

  return ''
}

export interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  disabled?: boolean
  error?: boolean
  glyph?: string
  glyphAlign?: GlyphAlign
  glyphType?: GlyphType
  lengthLimit?: number
  type?: string
  tabIndex?: number
  value?: string
}

export const TextInput: React.FC<TextInputProps> = props => {
  const {
    disabled = false,
    error = false,
    glyph = null,
    glyphAlign = 'left',
    glyphType = 'regular',
    lengthLimit,
    onChange = () => {},
    onClick = () => {},
    type = 'text',
    value = ''
  } = props

  let icon = null

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (!lengthLimit || remainingCount(lengthLimit, evt.target.value) >= 0) {
      onChange(evt)
    }
  }

  const iconClass = disabled
    ? [styles.text_input_icon_disabled]
    : [styles.text_input_icon]

  switch (glyphAlign) {
    case 'left':
      iconClass.push(styles.text_input_icon_left)
      break
    case 'right':
      iconClass.push(styles.text_input_icon_right)
      break
    default:
  }

  if (glyph) {
    icon = (
      <Icon
        glyph={glyph}
        className={iconClass.join(' ')}
        type={glyphType as GlyphType}
      />
    )
  }

  const inputStyles = [styles.text_input]

  if (error) {
    inputStyles.push(styles.text_input_error)
  }

  if (lengthLimit && lengthLimit > 0) {
    inputStyles.push(styles.text_input_text_limit)
  }

  return (
    <div onClick={onClick} className={styles.text_input_wrapper}>
      {icon}
      <input
        {...props}
        onClick={undefined}
        type={type}
        className={inputStyles.join(' ')}
        onChange={handleChange}
      />
      {renderRemainingCount(lengthLimit, value)}
    </div>
  )
}

import React from 'react'
import styles from './styles.module.css'

export interface Props {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  selected?: boolean
  testId?: string
}

export const SubNavItem: React.FC<Props> = ({
  onClick = () => {},
  selected = false,
  testId,
  children
}) => (
  <div
    className={selected ? styles.selectedItem : styles.subnavItem}
    onClick={onClick}
    data-test-id={testId}
  >
    {children}
  </div>
)

import React from 'react'

import { SeverityIcon } from '../SeverityIcon'
import { SymptomTitle } from '../SymptomTitle'

import { Symptom } from '../../types/healthTracker'

import styles from './styles.module.css'

/**
 * NOTE: This component is unfinished, but added to the library
 * to prevent duplicate work after higher priority work is completed.
 * It is intended to be used on Health Tracker workboards.
 */

export interface SymptomItemProps {
  symptom: Symptom
  isCollapsed: boolean
}

export const SymptomItem: React.FC<SymptomItemProps> = ({
  symptom,
  isCollapsed = false
}) => (
  <div className={styles.statusMain}>
    <div className={styles.statusContainer}>
      {symptom.severity && <SeverityIcon severity={symptom.severity} />}
      <SymptomTitle symptom={symptom} />
    </div>
    {!isCollapsed && (
      <>
        <p className={styles.statusNote}>{symptom.note}</p>
        {symptom.details &&
          symptom.details.map((detail: any) => (
            <p key={detail} className={styles.statusNote}>
              {detail}
            </p>
          ))}
        {symptom.comment && (
          <p className={styles.statusComment}>
            &quot;
            {symptom.comment}
            &quot;
          </p>
        )}
      </>
    )}
  </div>
)

import React from 'react'
import styles from './styles.module.css'

export interface Props {
  children: React.ReactNode
}

export const TabNav: React.FC<Props> = ({ children }) => (
  <div className={styles.tabNav}>{children}</div>
)

import React from 'react'
import { Omit } from 'react-router'
import { TextInput, TextInputProps } from '../TextInput'

type OnChange = (name: string, newValue: string) => void

export interface Props extends Omit<TextInputProps, 'onChange'> {
  onChange: OnChange
  value: string
  name: string
}

const shouldInsertSlash = (value: string) =>
  /^(\d{3}|\d{2}\/\d{3})$/.test(value)
const validDate = (value: string) =>
  /^(\d{0,2}|\d{2}\/\d{0,2}|\d{2}\/\d{2}\/\d{0,4})$/.test(value)

const validateDate = (onChange: OnChange, name: string) => (
  event: React.FormEvent<HTMLInputElement>
) => {
  let newValue = event.currentTarget.value
  if (shouldInsertSlash(newValue))
    newValue = `${newValue.slice(0, -1)}/${newValue.slice(-1)}`
  if (!validDate(newValue)) return

  onChange(name, newValue)
}

// Valid format of DateInput is MM/DD/YYYY ex. 01/09/2018
export const DateInput: React.FC<Props> = ({
  onChange,
  value,
  name,
  ...props
}: Props) => {
  return (
    <TextInput
      {...props}
      name={name}
      onChange={validateDate(onChange, name)}
      value={value}
    />
  )
}

DateInput.defaultProps = {
  value: '',
  placeholder: '',
  name: '',
  disabled: false,
  glyph: undefined,
  glyphAlign: 'left',
  glyphType: 'regular',
  type: 'text',
  error: false
}

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

export const GridRow: React.FC<{
  children?: React.ReactNode
  className?: string
}> = ({ children, className }) => (
  <div className={classNames(styles.grid_row, className)}>{children}</div>
)

GridRow.defaultProps = {
  children: ''
}

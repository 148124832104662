import React from 'react'
import { MultiSelectField } from '../MultiSelectField'
import { SingleSelectField } from '../SingleSelectField'
import { FilterProps } from './Filter.interface'
import styles from './styles.module.css'

export interface Props {
  onFilterChange: (value: any) => void
  selectedFilter?: any
}

export declare type InnerProps = Props & FilterProps

const FilterField: React.FC<InnerProps> = (props: InnerProps) => {
  const {
    name,
    label,
    selectedFilter,
    options,
    onFilterChange,
    singleSelect,
    placeholder = 'Select'
  } = props
  return (
    <>
      <label className={styles.filterLabel}>{label}</label>
      {singleSelect ? (
        <SingleSelectField
          name={name}
          placeholder={placeholder}
          value={selectedFilter}
          onChange={(_fieldName, value) => onFilterChange(value)}
          options={options}
        />
      ) : (
        <MultiSelectField
          name={name}
          placeholder={placeholder}
          values={selectedFilter}
          onChange={(_fieldName, value) => onFilterChange(value)}
          options={options}
        />
      )}
    </>
  )
}

export default FilterField

import React from 'react'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import { v4 } from 'uuid'
import { Icon } from '../Icon'
import styles from './styles.module.css'

export interface Props {
  children?: React.ReactNode
  className?: string
  isOpen?: boolean
  onClick?: (event: React.SyntheticEvent) => void
  title?: string | React.ReactNode
}

export const CollapsibleContainer: React.FC<Props> = ({
  children = null,
  className = '',
  isOpen = false,
  onClick = () => {},
  title = ''
}) => {
  const buttonId = v4()
  const containerId = v4()
  const onKeyDown = onClick
    ? (event: React.KeyboardEvent): void => {
        if (event.keyCode === 13) onClick(event)
      }
    : undefined

  return (
    <div className={classNames([className])}>
      <div
        onClick={onClick}
        onKeyDown={onKeyDown}
        className={styles.dropdown}
        tabIndex={0}
        id={buttonId}
        role="button"
        aria-expanded={isOpen}
        aria-controls={containerId}
      >
        <Icon
          glyph={isOpen ? 'chevron-up' : 'chevron-down'}
          className={styles.chevron}
        />
        {typeof title === 'string' ? (
          <span className={styles['title--default']}>{title}</span>
        ) : (
          title
        )}
      </div>
      <div role="region" id={containerId} aria-labelledby={buttonId}>
        <CSSTransition
          appear={isOpen}
          in={isOpen}
          timeout={400}
          classNames={{
            appear: styles.collapsibleEnterDone,
            appearActive: styles.collapsibleEnterDone,
            appearDone: styles.collapsibleEnterDone,
            enter: styles.collapsibleEnter,
            enterActive: styles.collapsibleEnterActive,
            enterDone: styles.collapsibleEnterDone,
            exit: styles.collapsibleExit,
            exitActive: styles.collapsibleExitActive,
            exitDone: styles.collapsibleExitDone
          }}
        >
          <>
            <div className={styles.collapsibleContent}>{children}</div>
          </>
        </CSSTransition>
      </div>
    </div>
  )
}

export default CollapsibleContainer

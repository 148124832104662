import React, { useState, useRef } from 'react'
import { SingleSelectField } from '../SingleSelectField'
import styles from './styles.module.css'
import { Tooltip } from '../Tooltip'
import { Icon } from '../Icon'

export type Mode = 'default' | 'changed' | 'error'

export interface Props<V = string | number> {
  colspan?: number
  rowspan?: number
  width?: number
  mode?: Mode
  placeholder?: string
  value: string | number
  name: string
  options: {
    value: V
    label: string
  }[]
  onChange: (name: string, value: V) => void
}

export const TableCellSingleSelectField: React.FC<Props> = ({
  colspan,
  rowspan,
  width,
  mode,
  placeholder,
  value,
  name,
  options,
  onChange
}) => {
  const [tooltipShown, setTooltipShown] = useState(false)
  const ellipsesEl = useRef<HTMLSpanElement>(null)
  const tooltipIsNecessary = () =>
    Boolean(
      ellipsesEl &&
        ellipsesEl.current &&
        (ellipsesEl.current.offsetWidth < ellipsesEl.current.scrollWidth ||
          ellipsesEl.current.offsetHeight < ellipsesEl.current.scrollHeight)
    )

  const chosen = options.find(option => `${option.value}` === `${value}`)
  const tooltipTitle = chosen ? chosen.label : ''

  const classNames = [styles.table_cell_single_select_field]
  if (mode === 'error') classNames.push(styles.error)
  if (mode === 'changed') {
    if (value) classNames.push(styles.changed)
    if (!value) classNames.push(styles.removed)
  }

  return (
    <td
      className={classNames.join(' ')}
      colSpan={colspan}
      rowSpan={rowspan}
      style={{ width }}
    >
      <Tooltip
        className={styles.table_cell_tooltip}
        tooltipPlacement="bottom"
        tooltip={tooltipTitle}
        trigger="none"
        tooltipShown={tooltipShown}
        setTooltipShown={setTooltipShown}
      >
        <SingleSelectField
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(_, v) => {
            onChange(_, v)
            setTooltipShown(false)
          }}
          options={options}
          onFocus={() => setTooltipShown(tooltipIsNecessary())}
          onBlur={() => setTooltipShown(false)}
          onMouseEnter={() => setTooltipShown(tooltipIsNecessary())}
          onMouseLeave={() => setTooltipShown(false)}
        />
        <div className={styles.hiddenContainer}>
          <div className={styles.hiddenWrapper}>
            <span ref={ellipsesEl} className={styles.hiddenEl}>
              {tooltipTitle}
            </span>
            <Icon className={styles.hiddenIcon} glyph="chevron-up" />
          </div>
        </div>
      </Tooltip>
    </td>
  )
}

TableCellSingleSelectField.defaultProps = {
  colspan: 1,
  rowspan: 1,
  mode: 'default'
}

import React, { Component } from 'react'
import styles from './styles.module.css'
import { PhoneInput } from '../PhoneInput'
import { TextInput } from '../TextInput'
import { SelectFieldMenu } from '../SelectFieldMenu'
import { formatPhoneNumber } from '../../utilities/formatPhoneNumber'
import { WithIsOpen } from '../../hocs/withIsOpen'

export type ModeType = 'phone' | 'text'
export interface Props {
  disabled?: boolean
  error?: boolean
  mode?: ModeType
  textLimit?: number
  name: string
  onChange: (name: string, value: string | number) => void
  onBlur?: (event: React.FormEvent) => void
  options: string[]
  placeholder: string
  value: string
}

export interface State {
  enableFiltering: boolean
}

export type InnerProps = Props & WithIsOpen

export class Layout extends Component<InnerProps, State> {
  private node: HTMLDivElement | null

  public static defaultProps = {
    disabled: false,
    error: false,
    mode: 'text' as ModeType,
    onChange: () => {},
    onBlur: () => {},
    options: [],
    placeholder: '',
    value: ''
  }

  public constructor(props: InnerProps) {
    super(props)
    this.node = null
    this.handleOptionSelect = this.handleOptionSelect.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handlePhoneFilterTextChange = this.handlePhoneFilterTextChange.bind(
      this
    )
    this.handleEventFilterTextChange = this.handleEventFilterTextChange.bind(
      this
    )
    this.state = {
      enableFiltering: false
    }
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  private handleDocumentClick(evt: Event) {
    const { setIsOpen } = this.props
    if (this.node && !this.node.contains(evt.target as HTMLElement)) {
      setIsOpen(false)
    }
  }

  private handleOptionSelect(value: string | number) {
    const { name, onChange } = this.props
    onChange(name, value)
  }

  private handlePhoneFilterTextChange(phoneValue: string) {
    this.changeText(phoneValue)
  }

  private handleEventFilterTextChange(evt: React.FormEvent<HTMLInputElement>) {
    const { value } = evt.target as HTMLInputElement
    this.changeText(value)
  }

  private isPhoneMode() {
    const { mode } = this.props
    return mode === 'phone'
  }

  private changeText(value: string) {
    const { name, onChange, setIsOpen } = this.props
    this.setState({ enableFiltering: true })
    onChange(name, value)
    setIsOpen(true)
  }

  private filterOptions() {
    const { options, value } = this.props
    const { enableFiltering } = this.state

    if (!enableFiltering) {
      return options
    }

    return options.filter(option =>
      option.toLowerCase().includes(value.toLowerCase())
    )
  }

  private renderInput() {
    const {
      disabled,
      error,
      name,
      placeholder,
      value,
      textLimit,
      onBlur
    } = this.props

    if (this.isPhoneMode()) {
      return (
        <PhoneInput
          disabled={!!disabled}
          error={!!error}
          name={name}
          onChange={this.handlePhoneFilterTextChange}
          placeholder={placeholder || ''}
          value={value}
        />
      )
    }

    return (
      <TextInput
        disabled={disabled}
        error={error}
        name={name}
        onChange={this.handleEventFilterTextChange}
        onBlur={onBlur}
        placeholder={placeholder}
        value={value}
        lengthLimit={textLimit}
      />
    )
  }

  private renderOptions() {
    const { setIsOpen } = this.props

    const filteredOptions = this.filterOptions()
    if (!filteredOptions || filteredOptions.length === 0) return null

    const presentedOptions = filteredOptions.map(option => ({
      label: this.isPhoneMode() ? formatPhoneNumber(option) : option,
      value: option
    }))

    return (
      <div className={styles.menu}>
        <SelectFieldMenu
          closeMenu={() => setIsOpen(false)}
          onChange={this.handleOptionSelect}
          options={presentedOptions}
        />
      </div>
    )
  }

  public render() {
    const {
      isOpen,
      name,
      openAndCloseWithKeyboard,
      toggleIsOpen,
      value
    } = this.props

    return (
      <div
        ref={node => {
          this.node = node
        }}
        className={styles.field}
        onClick={toggleIsOpen}
        onKeyDown={openAndCloseWithKeyboard}
      >
        <form autoComplete="off">{this.renderInput()}</form>
        {isOpen && this.renderOptions()}
        <input type="hidden" name={name} value={value} />
      </div>
    )
  }
}

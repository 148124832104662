import React from 'react'
import classnames from 'classnames'
import { Checkbox } from '../Checkbox'
import styles from './styles.module.css'

export interface CheckboxPillProps {
  name: string
  checked?: boolean
  className?: string
  disabled?: boolean
  onChange?: () => void
  text?: string
  value?: string
}

export const CheckboxPill: React.FC<CheckboxPillProps> = ({
  name,
  checked = false,
  className = '',
  disabled = false,
  onChange = () => {},
  text,
  value = ''
}) => (
  <Checkbox
    checked={checked}
    className={classnames(
      styles.checkbox_override,
      styles.checkbox_pill,
      checked && styles['checkbox_pill--checked'],
      disabled && styles['checkbox_pill--disabled'],
      className
    )}
    name={name}
    onChange={onChange}
    value={value}
    text={text}
    disabled={disabled}
  />
)

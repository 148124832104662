import React from 'react'
import { FilterableInputWithOptions } from '../FilterableInputWithOptions'

export interface Props {
  emailAddresses: string[]
  errors?: {
    emailAddress?: string
    includeEmailAddress?: string
  }
  includeEmailAddress: boolean
  renderErrors: (errorMessage: string) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  values: {
    emailAddress: string
  }
}

export const EmailPicker: React.FC<Props> = ({
  errors = {},
  includeEmailAddress,
  renderErrors,
  setFieldValue,
  values,
  emailAddresses
}) => (
  <>
    <FilterableInputWithOptions
      disabled={!includeEmailAddress}
      error={!!errors.emailAddress}
      mode="text"
      name="emailAddress"
      onChange={setFieldValue}
      options={emailAddresses}
      value={values.emailAddress}
      placeholder={values.emailAddress}
    />
    {includeEmailAddress &&
      errors.emailAddress &&
      renderErrors(errors.emailAddress)}
  </>
)

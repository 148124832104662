import React from 'react'
import { SeverityIcon } from '../types'

export const SeverityMild: React.FC<SeverityIcon> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={className}
  >
    <path fill="none" d="M0 0h18v18H0z" />
    <circle cx="9" cy="9" r="7" fill="#e4d455" />
  </svg>
)

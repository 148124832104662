import React from 'react'
import styles from './styles.module.css'
import { FilterProps } from './Filter.interface'
import { Chip } from '../Chip'

export interface Props {
  filter: FilterProps
  removeFilter: (name: string, index: number) => void
}

const renderChip = (
  name: string,
  index: number,
  label: string,
  removeFilter: (name: string, index: number) => void
) => {
  return (
    <div className={styles.chipDiv} key={`value${name}${label}`}>
      <Chip
        onDismiss={() => {
          removeFilter(name, index)
        }}
        text={label}
        variant="default"
      />
    </div>
  )
}

const FilterChip: React.FC<Props> = (props: Props) => {
  const { filter, removeFilter } = props
  const { selectedValues, name } = filter
  if (selectedValues instanceof Array) {
    return (
      <>
        {selectedValues.map((value: any, index) => {
          const filterOption = filter.options.find(
            option => option.value === value
          )
          if (filterOption) {
            return renderChip(name, index, filterOption.label, removeFilter)
          }
          return null
        })}
      </>
    )
  }
  const filterOption = filter.options.find(
    option => option.value === selectedValues
  )
  if (filterOption) {
    return renderChip(name, 0, filterOption.label, removeFilter)
  }
  return null
}

export default FilterChip

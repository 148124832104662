import React from 'react'
import styles from './styles.module.css'

export interface Props {
  children: React.ReactNode
}

export const Table: React.FC<Props> = ({ children }) => (
  <table role="grid" className={styles.table} cellPadding="0" cellSpacing="0">
    {children}
  </table>
)

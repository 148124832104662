import { KeyboardEvent } from 'react'
import {
  compose,
  InferableComponentEnhancerWithProps,
  withHandlers,
  withState
} from 'recompose'

export type ExtractTInner<T> = T extends InferableComponentEnhancerWithProps<
  infer TInner,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>
  ? TInner
  : never

export interface WithIsOpen {
  isOpen: boolean
  setIsOpen: (open: boolean) => boolean
  toggleIsOpen: () => void
  openAndCloseWithKeyboard: (evt: React.KeyboardEvent<Element>) => void
}

export const withIsOpenState = withState('isOpen', 'setIsOpen', false)

type ToggleIsOpenOuter = ExtractTInner<typeof withIsOpenState> & {
  disabled?: boolean
}

interface ToggleIsOpen {
  toggleIsOpen: () => void
}

export const withToggleIsOpen = withHandlers<ToggleIsOpenOuter, ToggleIsOpen>({
  toggleIsOpen: ({ disabled = false, isOpen, setIsOpen }) => () => {
    setIsOpen(!disabled && !isOpen)
  }
})

interface KeyboardOpenClose {
  openAndCloseWithKeyboard: (evt: KeyboardEvent) => void
}

export const withKeyboardOpenCloseInteraction = withHandlers<
  ToggleIsOpenOuter,
  KeyboardOpenClose
>({
  openAndCloseWithKeyboard: ({ disabled = false, isOpen, setIsOpen }) => (
    evt: KeyboardEvent
  ) => {
    if (disabled) {
      return
    }

    if (evt.keyCode === 13) {
      evt.stopPropagation()
      evt.preventDefault()
      setIsOpen(true)
    } else if (isOpen && evt.keyCode === 27) {
      evt.stopPropagation()
      evt.preventDefault()
      setIsOpen(false)
    }
  }
})

export const withIsOpen = <T>() =>
  compose<T & ToggleIsOpen & KeyboardOpenClose & ToggleIsOpenOuter, T>(
    withIsOpenState,
    withToggleIsOpen,
    withKeyboardOpenCloseInteraction
  )

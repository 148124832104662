import React from 'react'
import styles from './styles.module.css'

export type AlertColor = 'green' | 'purple' | 'red'

export interface AlertProps {
  color?: AlertColor
  onClick?: (event: React.MouseEvent) => void
}

export const Alert: React.FC<AlertProps> = ({
  color = 'green',
  onClick,
  children
}) => {
  const classNames = [styles.alert, styles[`alert--${color}`]]

  if (onClick) {
    classNames.push(styles['alert--clickable'])
  }

  return (
    <span className={classNames.join(' ')} onClick={onClick}>
      {children}
    </span>
  )
}

import React from 'react'
import styles from './styles.module.css'

export interface Props {
  children: React.ReactNode
}

export const TableHeader: React.FC<Props> = ({ children }) => (
  <thead>
    <tr className={styles.table_row}>{children}</tr>
  </thead>
)

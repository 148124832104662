import React from 'react'
import { SeverityIcon } from '../types'

export const SeveritySevere: React.FC<SeverityIcon> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={className}
  >
    <path fill="none" d="M0 0h18v18H0z" />
    <path
      fill="#ff846b"
      fillRule="evenodd"
      d="M15.74 15.78H2.26a1 1 0 01-.87-1.51L8.14 2.72a1 1 0 011.36-.36 1 1 0 01.36.36l6.75 11.55a1 1 0 01-.36 1.37 1 1 0 01-.51.14z"
    />
  </svg>
)

import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'
import { Button } from '../Button'
import { Icon } from '../Icon'
import { SubmitButton } from '../SubmitButton'

import {
  FormFooterNotice,
  Props as FormFooterNoticeProps
} from './FormFooterNotice'

import {
  DeprecatedFormFooterNotice,
  Props as DeprecatedFormFooterNoticeProps
} from './DeprecatedFormFooterNotice'

export interface FormFooterProps {
  backUrl?: string
  handleSubmit: () => void
  isSubmitting: boolean
  isComplete?: boolean
  notice?: FormFooterNoticeProps
  showDivider?: boolean
  // status is deprecated, prefer specifying a type and text instead
  status?: DeprecatedFormFooterNoticeProps | 'complete'
  submitButtonTxt?: string
  submitButtonDisabled?: boolean
  cancelButtonTxt?: string
  showSecondaryButton?: boolean
  secondaryButtonTxt?: string
  handleSecondarySubmit?: () => void
  handleCancel?: () => void
  showFakeFooter?: boolean
}

const secondaryButtonContent = (
  content: string | undefined,
  {
    isDisabled = false,
    isSubmitting = false,
    isComplete = false
  }: { isDisabled?: boolean; isSubmitting?: boolean; isComplete?: boolean }
) => {
  if (isComplete) return <Icon glyph="check-circle" type="solid" />
  if (isSubmitting) return <Icon glyph="spinner" spin />
  if (isDisabled) return content
  return content
}

export const FormFooter: React.FC<FormFooterProps> = ({
  backUrl,
  handleSubmit,
  isSubmitting,
  isComplete,
  notice,
  showDivider,
  status,
  submitButtonTxt,
  submitButtonDisabled,
  cancelButtonTxt,
  showSecondaryButton,
  secondaryButtonTxt,
  handleSecondarySubmit,
  handleCancel,
  showFakeFooter
}) => (
  <>
    <div
      className={
        !showDivider
          ? `${styles.fixedFooter} ${styles.hideDivider}`
          : styles.fixedFooter
      }
    >
      <Button
        variant="tertiary"
        onClick={() => {
          if (backUrl) {
            window.location.href = backUrl
          } else if (handleCancel) {
            handleCancel()
          }
        }}
        disabled={isSubmitting || isComplete}
      >
        {cancelButtonTxt}
      </Button>
      <div className={styles.buttonContainer}>
        {status && status !== 'complete' && (
          <DeprecatedFormFooterNotice {...status} />
        )}
        {notice && <FormFooterNotice {...notice} />}

        {showSecondaryButton && (
          <span
            className={classnames(
              styles.secondaryButton,
              isComplete ? styles['secondaryButton--complete'] : ''
            )}
          >
            <Button
              variant="secondary"
              onClick={handleSecondarySubmit}
              disabled={submitButtonDisabled || isSubmitting}
            >
              {secondaryButtonContent(secondaryButtonTxt, {
                isComplete,
                isSubmitting,
                isDisabled: submitButtonDisabled
              })}
            </Button>
          </span>
        )}
        <span className={styles.submitButton}>
          <SubmitButton
            onSubmit={handleSubmit}
            text={submitButtonTxt}
            isComplete={isComplete || status === 'complete'}
            isValid={!submitButtonDisabled}
            isSubmitting={isSubmitting}
          />
        </span>
      </div>
    </div>
    {showFakeFooter && <div className={styles.fakeFooter} />}
  </>
)

FormFooter.defaultProps = {
  showDivider: true,
  submitButtonTxt: 'Save and Close',
  cancelButtonTxt: 'Cancel',
  showSecondaryButton: false,
  showFakeFooter: true
}

/* eslint-disable jsx-a11y/no-autofocus */

import React, { useEffect, useRef, useState, Ref } from 'react'
import styles from './styles.module.css'
import { Icon } from '../Icon'
import { Button } from '../Button'
import { Tooltip } from '../Tooltip'

export type Mode = 'default' | 'editing' | 'changed' | 'error'

const renderViewMode = (
  value: string,
  mode: Mode,
  placeholder: string,
  onEdit: (event: React.SyntheticEvent) => void,
  ellipsesEl: Ref<HTMLSpanElement>
) => {
  const placeholderStyleClass = value.length === 0 ? styles.placeholder : ''
  const containerClasses = [styles.table_cell_text_input]

  return (
    mode !== 'editing' && (
      <div className={containerClasses.join(' ')}>
        <span
          ref={ellipsesEl}
          className={`${styles.view_mode_label} ${
            styles.table_cell__text_content
          } ${placeholderStyleClass}`}
        >
          {value || placeholder}
        </span>
        <Button
          variant="tertiary"
          className={styles.edit_button}
          onClick={event => onEdit(event)}
        >
          <Icon glyph="pencil" type="solid" align="right" />
        </Button>
      </div>
    )
  )
}

const renderEditMode = (
  name: string,
  value: string,
  mode: Mode,
  placeholder: string,
  onChange: (event: React.SyntheticEvent) => void,
  onConfirm: (event: React.SyntheticEvent) => void,
  onCancel: (event: React.SyntheticEvent) => void
) => {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    onChange(evt)
  }

  return (
    mode === 'editing' && (
      <div className={styles.table_cell_text_input}>
        <div className={styles.editable_container}>
          <input
            type="text"
            name={name}
            className={`${styles.text_input_field} ${
              styles.table_cell__text_content
            }`}
            value={value}
            onChange={handleChange}
            placeholder={placeholder}
            autoFocus
            onFocus={e => e.currentTarget.select()}
          />
          <span className={styles.edit_mode_buttons}>
            <span className={styles.confirm_button}>
              <Button variant="tertiary" onClick={onConfirm}>
                <Icon glyph="check" type="solid" />
              </Button>
            </span>
            <span className={styles.cancel_button}>
              <Button variant="tertiary" onClick={onCancel}>
                <Icon glyph="times" type="solid" />
              </Button>
            </span>
          </span>
        </div>
      </div>
    )
  )
}

export interface Props {
  name: string
  value: string
  mode?: Mode | undefined
  placeholder?: string
  onEdit: (event: React.SyntheticEvent) => void
  onChange: (event: React.SyntheticEvent) => void
  onConfirm: (event: React.SyntheticEvent) => void
  onCancel: (event: React.SyntheticEvent) => void
  colspan?: number
  rowspan?: number
  width?: number
}

export const TableCellTextInput: React.FC<Props> = ({
  name,
  value,
  mode = 'default',
  placeholder = '',
  onEdit,
  onChange,
  onConfirm,
  onCancel,
  colspan = 0,
  rowspan = 0,
  width = undefined
}) => {
  const [triggerTooltip, setTriggerTooltip] = useState(false)
  const ellipsesEl = useRef<HTMLSpanElement>(null)
  useEffect(() => {
    if (ellipsesEl.current) {
      const trigger =
        ellipsesEl.current.offsetHeight < ellipsesEl.current.scrollHeight ||
        ellipsesEl.current.offsetWidth < ellipsesEl.current.scrollWidth
      setTriggerTooltip(trigger)
    }
  })

  const classNames = [styles.table_cell]
  if (mode === 'error') classNames.push(styles.error)
  if (mode === 'changed') {
    if (value) classNames.push(styles.changed)
    if (!value) classNames.push(styles.removed)
  }

  return (
    <td
      className={classNames.join(' ')}
      colSpan={colspan}
      rowSpan={rowspan}
      style={{ width }}
    >
      {mode === 'editing' ? (
        renderEditMode(
          name,
          value,
          mode,
          placeholder,
          onChange,
          onConfirm,
          onCancel
        )
      ) : (
        <Tooltip
          className={styles.table_cell_tooltip}
          tooltipPlacement="bottom"
          trigger={
            value && triggerTooltip ? ['hover', 'focus', 'click'] : 'none'
          }
          tooltip={value}
        >
          {renderViewMode(value, mode, placeholder, onEdit, ellipsesEl)}
        </Tooltip>
      )}
    </td>
  )
}

TableCellTextInput.defaultProps = {
  mode: 'default',
  placeholder: 'Text Input',
  colspan: 1,
  rowspan: 1
}

export default TableCellTextInput

import React from 'react'
import { SeverityIcon } from '../types'

export const SeverityVerySevere: React.FC<SeverityIcon> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    className={className}
  >
    <path fill="none" d="M0 0h18v18H0z" />
    <rect
      width="11.89"
      height="11.89"
      x="3.05"
      y="3.05"
      fill="#d0021b"
      rx="0.99"
      transform="rotate(-45 8.999 9.003)"
    />
  </svg>
)

import React, { Component } from 'react'
import Filters from './Filters'
import FilterOptions from './FilterOptions'
import {
  FilterOption,
  Option,
  SelectedFilter,
  SelectedFilterValues
} from './Filter.interface'

export interface Props {
  showPopulationFilter?: boolean
  showProviderFilter?: boolean
  showLocationFilter?: boolean
  showTherapyFilter?: boolean
  onDismiss?: (() => void) | null
  onApply?: ((values: SelectedFilterValues) => void) | null
  onClearAll?: (values: SelectedFilterValues) => void
  onRemove?: (() => void) | null
  filterOptions: FilterOption
  localStorageKey?: string
}

export interface State {
  isHidden: boolean
  selectedFilter: SelectedFilter
  previousSelectedFilter: SelectedFilter
  lastRefresh: Date
}

const LOCAL_STORAGE_KEY_PREFIX = 'NC.ReactComponents.FilterPanel'

export class FilterPanel extends Component<Props, State> {
  public static defaultProps = {
    filterOptions: {
      locationOptions: [],
      populationOptions: [],
      providerOptions: [],
      therapyOptions: []
    },
    onApply: () => {},
    onDismiss: () => {},
    onClearAll: () => {},
    onRemove: () => {},
    showPopulationFilter: true,
    showProviderFilter: true,
    showLocationFilter: true,
    showTherapyFilter: true
  }

  public constructor(props: Props) {
    super(props)
    this.state = {
      isHidden: true,
      lastRefresh: new Date(Date.now()),
      selectedFilter: this.getFiltersFromLocalStorage(),
      previousSelectedFilter: {}
    }
  }

  private computedStorageKey = () => {
    const { localStorageKey } = this.props
    return `${LOCAL_STORAGE_KEY_PREFIX}.${localStorageKey}`
  }

  private saveFiltersToLocalStorage = (jsonString: string) => {
    const { localStorageKey } = this.props
    if (localStorageKey) {
      localStorage.setItem(this.computedStorageKey(), jsonString)
    }
  }

  private getFiltersFromLocalStorage = () => {
    const { localStorageKey } = this.props
    if (localStorageKey) {
      return JSON.parse(localStorage.getItem(this.computedStorageKey()) || '{}')
    }
    return {}
  }

  private selectedValuesObject = (
    filterOptions: FilterOption,
    values: SelectedFilterValues
  ) => {
    const selectedFilters: SelectedFilter = {}
    const {
      providerOptions,
      locationOptions,
      populationOptions,
      therapyOptions
    } = filterOptions
    if (values.locations && values.locations.length > 0) {
      const selectedLocations: Option[] = []
      values.locations.forEach((value: string) => {
        const location: any = locationOptions.find((loc) => loc.value === value)
        selectedLocations.push(location)
      })
      selectedFilters.locations = selectedLocations
    }
    if (values.providers && values.providers.length > 0) {
      const selectedProviders: Option[] = []
      values.providers.forEach((value: string) => {
        const provider: any = providerOptions.find((pro) => pro.value === value)
        selectedProviders.push(provider)
      })
      selectedFilters.providers = selectedProviders
    }
    if (values.population) {
      const population = populationOptions.find(
        (pop) => pop.value === values.population
      )
      selectedFilters.population = population
    }
    if (values.therapies && values.therapies.length > 0) {
      const selectedTherapies: Option[] = []
      values.therapies.forEach((value: string) => {
        const therapy: any = therapyOptions.find((ther) => ther.value === value)
        selectedTherapies.push(therapy)
      })
      selectedFilters.therapies = selectedTherapies
    }
    return selectedFilters
  }

  private selectedValues = (selectedFilter: SelectedFilter) => {
    const filterValues: SelectedFilterValues = {
      providers: [],
      locations: [],
      population: '',
      therapies: []
    }

    if (selectedFilter) {
      if (selectedFilter.locations && selectedFilter.locations.length > 0) {
        selectedFilter.locations.map((location: Option) =>
          filterValues.locations.push(location.value)
        )
      }
      if (selectedFilter.providers && selectedFilter.providers.length > 0) {
        selectedFilter.providers.map((provider: Option) =>
          filterValues.providers.push(provider.value)
        )
      }
      if (selectedFilter.population) {
        filterValues.population = selectedFilter.population.value
      }
      if (selectedFilter.therapies && selectedFilter.therapies.length > 0) {
        selectedFilter.therapies.map((therapy: Option) =>
          filterValues.therapies.push(therapy.value)
        )
      }
    }
    return filterValues
  }

  public toggleHidden = (isHidden: boolean) => {
    this.setState({ isHidden })
  }

  public updateSelectedFilter = (selectedFilter: SelectedFilter) => {
    this.saveFiltersToLocalStorage(JSON.stringify(selectedFilter))
    this.setState({
      selectedFilter: JSON.parse(JSON.stringify(selectedFilter))
    })
  }

  public togglePanel = () => {
    const { isHidden, previousSelectedFilter } = this.state
    const { onDismiss } = this.props
    if (!isHidden) {
      this.updateSelectedFilter(previousSelectedFilter)
    }
    this.toggleHidden(!isHidden)
    if (onDismiss) {
      onDismiss()
    }
  }

  public removeFilter = (index: number, type: keyof SelectedFilter) => {
    const { onRemove } = this.props
    const { selectedFilter } = this.state
    const filter = JSON.parse(JSON.stringify(selectedFilter))
    if (type !== 'population') {
      filter[type].splice(index, 1)
    } else {
      filter[type] = null
    }
    this.updateSelectedFilter(filter)
    this.toggleHidden(false)
    if (onRemove) {
      onRemove()
    }
  }

  public onSubmit = (values: SelectedFilterValues) => {
    const { filterOptions, onApply } = this.props
    const selectedFilter = this.selectedValuesObject(filterOptions, values)
    this.toggleHidden(true)
    this.updateSelectedFilter(selectedFilter)
    if (onApply) {
      onApply(values)
    }
    this.setState({
      previousSelectedFilter: JSON.parse(JSON.stringify(selectedFilter)),
      lastRefresh: new Date(Date.now())
    })
  }

  public onClearAll = (values: SelectedFilterValues) => {
    const { filterOptions, onClearAll } = this.props
    const selectedFilter = this.selectedValuesObject(filterOptions, values)
    this.toggleHidden(true)
    this.updateSelectedFilter(selectedFilter)
    if (onClearAll) {
      onClearAll(values)
    }
    this.setState({
      previousSelectedFilter: JSON.parse(JSON.stringify(selectedFilter))
    })
  }

  // Perform initial fetch
  public componentDidMount = () => {
    const { selectedFilter } = this.state
    this.onSubmit(this.selectedValues(selectedFilter))
  }

  public render() {
    const { isHidden, selectedFilter, lastRefresh } = this.state
    return (
      <>
        <Filters
          isHidden={isHidden}
          selectedFilter={selectedFilter}
          removeFilter={this.removeFilter}
          onToggle={this.togglePanel}
          lastRefresh={lastRefresh}
          onRefresh={() => this.onSubmit(this.selectedValues(selectedFilter))}
        />
        {!isHidden && 
          <FilterOptions
            {...this.props}
            filterValues={this.selectedValues(selectedFilter)}
            togglePanel={this.togglePanel}
            onClearAll={this.onClearAll}
            onSubmit={this.onSubmit}
          />
        }
      </>
    )
  }
}

import React from 'react'
import styles from './styles.module.css'
import { Checkbox } from '../Checkbox'
import { GridColumn } from '../GridColumn'

export interface Props {
  errors?: {
    includeOptInNumber?: string
  }
  includeOptInNumber: boolean
  renderErrors: (message: string) => React.ReactNode
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  values: Values
  optedOutNumbers: string[]
}

export interface Values {
  textMessageNumber: string
}

const renderOptInCheckbox = (
  includeOptInNumber: boolean,
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void,
  values: Values,
  optedOutNumbers: string[]
) => {
  const handleChange = (name: string, checked: boolean) => {
    if (optedOutNumbers.includes(values.textMessageNumber)) {
      setFieldValue(name, checked)
    }
  }

  return (
    <GridColumn span={8}>
      <div className={styles.optInCheckbox} data-jest="optInCheckbox">
        <Checkbox
          checked={includeOptInNumber}
          name="includeOptInNumber"
          onChange={handleChange}
          text="The recipient agrees to opt in to receiving secure links via text message."
        />
      </div>
    </GridColumn>
  )
}

export const OptInNumber: React.FC<Props> = ({
  errors = {},
  includeOptInNumber,
  renderErrors,
  setFieldValue,
  values,
  optedOutNumbers
}) => (
  <>
    {renderOptInCheckbox(
      includeOptInNumber,
      setFieldValue,
      values,
      optedOutNumbers
    )}
    <GridColumn span={8}>
      <div className={styles.optInCheckboxError}>
        {errors.includeOptInNumber && renderErrors(errors.includeOptInNumber)}
      </div>
    </GridColumn>
  </>
)

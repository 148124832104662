import React from 'react'
import styles from './styles.module.css'
import faStyles from '../../css/fontawesome.module.css'

export type GlyphType = 'light' | 'regular' | 'solid' | 'brands'
export type GlyphAlign = 'left' | 'right'

export interface Props {
  align?: '' | GlyphAlign
  glyph: string
  type?: GlyphType
  className?: string
  spin?: boolean
  color?: string
}

export const Icon: React.FC<Props> = ({
  align = '',
  className,
  glyph,
  spin = false,
  type = 'regular',
  color
}) => {
  const outerClassNames = [styles.icon, className]
  const innerClassNames = [faStyles['fa-fw'], faStyles[`fa-${glyph}`]]

  switch (type) {
    case 'light':
      innerClassNames.push(faStyles.fal)
      break
    case 'regular':
      innerClassNames.push(faStyles.far)
      break
    case 'solid':
      innerClassNames.push(faStyles.fas)
      break
    case 'brands':
      innerClassNames.push(faStyles.fab)
      break
    default:
      break
  }

  switch (align) {
    case 'left':
      outerClassNames.push(styles['icon--align_left'])
      break
    case 'right':
      outerClassNames.push(styles['icon--align_right'])
      break
    default:
      break
  }

  if (spin) {
    innerClassNames.push(faStyles['fa-pulse'])
  }

  return (
    <span className={outerClassNames.join(' ')} style={{ color }}>
      <i className={innerClassNames.join(' ')} />
    </span>
  )
}

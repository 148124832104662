import React from 'react'
import { format } from 'date-fns'
import styles from './styles.module.css'
import { NoFilters } from './NoFilters'
import { Icon } from '../Icon'
import { FilterProps } from './Filter.interface'
import { GridRow } from '../GridRow'
import { Button } from '../Button'
import FilterChip from './FilterChip'

export interface Props {
  filterData: FilterProps[]
  onToggle: () => void
  onRefresh?: () => void
  isHidden?: boolean
  removeFilter: (name: string, index: number) => void
  lastRefresh?: Date
  showRefresh?: boolean
}

// 3:01am
const REFRESHED_FORMAT = `h:mmaaaaa'm'`

export const Filters: React.FC<Props> = props => {
  const {
    isHidden,
    filterData,
    onToggle,
    onRefresh,
    removeFilter,
    showRefresh,
    lastRefresh = new Date(Date.now())
  } = props

  const doRefresh = () => {
    if (onRefresh) {
      onRefresh()
    }
  }

  const selectedFilter = (): FilterProps[] =>
    filterData.filter(
      item => item.selectedValues && item.selectedValues.length > 0
    )

  const chips = () => {
    const selectedFilterData = selectedFilter()
    return selectedFilterData.length <= 0 ? (
      <NoFilters />
    ) : (
      <>
        {selectedFilterData.map(filter => {
          return (
            <FilterChip
              key={`value${filter.name}`}
              filter={filter}
              removeFilter={removeFilter}
            />
          )
        })}
      </>
    )
  }

  return (
    <GridRow className={styles.barRow}>
      <div className={styles.bar}>
        <div className={styles.bar__expand}>
          <Button variant="tertiary" onClick={onToggle}>
            <Icon glyph={isHidden ? 'chevron-down' : 'chevron-up'} />
            &nbsp;Filters
          </Button>
        </div>
        <div className={styles.bar__chips}>{isHidden && chips()}</div>
        {showRefresh && (
          <div className={styles.bar__refresh}>
            <Button variant="tertiary" onClick={doRefresh}>
              <Icon glyph="sync" />
              <span className={styles.bar__refreshText}>
                &nbsp;Last refresh {format(lastRefresh, REFRESHED_FORMAT)}
              </span>
            </Button>
          </div>
        )}
      </div>
    </GridRow>
  )
}

export default Filters

import React from 'react'
import { withFormik, FormikProps } from 'formik'
import { GridColumn } from '../GridColumn'
import { GridRow } from '../GridRow'
import { Button } from '../Button'
import { FilterProps } from './Filter.interface'
import styles from './styles.module.css'
import FilterField from './FilterField'

export interface Props {
  onClearAll?: () => void
  filterData: FilterProps[]
  onSubmit: (values: any) => void
}

export declare type InnerProps = Props & FormikProps<any>

const FilterOptions: React.FC<InnerProps> = (props: InnerProps) => {
  const {
    values,
    handleSubmit,
    setFieldValue,
    filterData,
    resetForm,
    onClearAll,
    onSubmit
  } = props

  const clearAll = () => {
    const resetValues = {}
    resetForm(resetValues)
    if (onClearAll) {
      onClearAll()
    }
    onSubmit(resetValues)
  }

  const getEven = (_filter: FilterProps, index: number) => {
    return index % 2 === 0
  }

  const getOdd = (_filter: FilterProps, index: number) => {
    return index % 2 !== 0
  }

  return (
    <>
      <GridRow className={styles.filterRow}>
        <GridColumn span={6}>
          {filterData.filter(getEven).map(filter => (
            <FilterField
              {...filter}
              key={filter.name}
              onFilterChange={value => setFieldValue(filter.name, value)}
              selectedFilter={values[filter.name]}
            />
          ))}
        </GridColumn>
        <GridColumn span={6}>
          {filterData.filter(getOdd).map(filter => (
            <FilterField
              {...filter}
              key={filter.name}
              onFilterChange={value => setFieldValue(filter.name, value)}
              selectedFilter={values[filter.name]}
            />
          ))}
        </GridColumn>
      </GridRow>
      <GridRow className={styles.buttonRow}>
        <GridColumn span={6} />
        <GridColumn span={3}>
          <Button
            className={styles.buttonRow__button}
            variant="secondary"
            onClick={clearAll}
          >
            Clear All
          </Button>
        </GridColumn>
        <GridColumn span={3}>
          <Button
            variant="primary"
            className={styles.buttonRow__button}
            onClick={(event: any) => handleSubmit(event)}
          >
            Apply Filters
          </Button>
        </GridColumn>
      </GridRow>
    </>
  )
}

export default withFormik<any, any>({
  enableReinitialize: true,
  mapPropsToValues: (props: InnerProps) => {
    const values: any = {}
    props.filterData.forEach(filter => {
      const { name, selectedValues } = filter
      values[name] = selectedValues
    })
    return values
  },
  handleSubmit: (values, { props }) => {
    props.onSubmit(values)
  }
})(FilterOptions)

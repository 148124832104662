import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

export type Variant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'success'
  | 'neutral'

export interface ButtonProps {
  domId?: string | undefined
  dataTestId?: string | undefined
  ariaExpanded?: boolean | undefined
  ariaControls?: string | undefined
  disabled?: boolean
  flush?: boolean
  variant?: Variant
  onClick?: (event: React.SyntheticEvent) => void
  className?: string
}

export const Button: React.FC<ButtonProps> = ({
  domId,
  dataTestId,
  variant = 'primary',
  disabled,
  flush,
  onClick,
  children,
  className,
  ariaExpanded = false,
  ariaControls
}) => {
  const classes = [styles.button, styles[`button--${variant}`], className]

  const onKeyDown = onClick
    ? (event: React.KeyboardEvent): void => {
        if (event.keyCode === 13) onClick(event)
      }
    : undefined

  if (disabled) {
    classes.push(styles[`button--${variant}--disabled`])
  }

  if (flush) {
    classes.push(styles[`button--flush`])
  }

  return (
    <button
      type="button"
      id={domId}
      data-testid={dataTestId}
      className={classNames(classes)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
    >
      {children}
    </button>
  )
}

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

type Variant = 'default' | 'mini'

export interface Props {
  disabled?: boolean
  href: string
  target: '_self' | '_blank' | '_parent' | '_top'
  children: React.ReactNode
  variant?: Variant
  className?: string
  stopClickEventPropagation?: boolean
}

export class Link extends React.Component<Props> {
  public static defaultProps = {
    disabled: false,
    target: '_self',
    children: '',
    variant: 'default',
    stopClickEventPropagation: true
  }

  private activeLink() {
    const {
      href,
      target,
      children,
      variant,
      className,
      stopClickEventPropagation
    } = this.props

    return (
      <a
        href={href}
        rel="noopener noreferrer"
        target={target}
        className={classNames(
          styles.link,
          styles[`link--${variant}`],
          className
        )}
        onClick={(evt) => {
          // Prevents other event handlers from handling the click event emitted
          // by this link. This is the default behavior of this component, but
          // can be overridden with the `stopClickEventPropagation: false` prop.
          if (stopClickEventPropagation) evt.stopPropagation()
        }}
      >
        {children}
      </a>
    )
  }

  private disabledLink() {
    const { children, variant } = this.props
    return (
      <span
        className={classNames(
          styles.link__disabled,
          styles[`link--${variant}`]
        )}
      >
        {children}
      </span>
    )
  }

  public render() {
    const { disabled } = this.props
    return disabled ? this.disabledLink() : this.activeLink()
  }
}

import React from 'react'
import { AlertNotice } from '../Notice'

export interface Props {
  error?: string
}

export const DeprecatedFormFooterNotice: React.FC<Props> = ({ error }) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.warn(
      'FormFooter.status is deprecated and will be removed in future versions. Use FormFooter.notice instead.'
    )
    return <AlertNotice text={error} />
  }
  return null
}

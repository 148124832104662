import React from 'react'
import { Checkbox } from '../Checkbox'
import styles from './styles.module.css'

type OnChange = (name: string, checked: boolean) => void

export type Mode = 'default' | 'changed'

export interface Props {
  colspan?: number
  rowspan?: number
  width?: number
  value: string
  name: string
  checked: boolean
  mode?: Mode
  onChange: OnChange
}

const computeBackgroundColorClass = (props: Props) => {
  const { checked, mode } = props
  let backgroundColorClass = ''

  if (mode === 'changed') {
    backgroundColorClass = checked
      ? styles.table_cell_checkbox__verylightgreen_bg
      : styles.table_cell_checkbox__lightpink_bg
  }
  return backgroundColorClass
}

export const TableCellCheckbox: React.FC<Props> = props => {
  const { colspan, rowspan, width, value, name, checked, onChange } = props
  const bgColor = computeBackgroundColorClass(props)

  return (
    <td
      className={`${styles.table_cell_checkbox} ${bgColor}`}
      colSpan={colspan}
      rowSpan={rowspan}
      style={{ width }}
    >
      <div className={styles.checkbox__wrapper}>
        <Checkbox
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          renderInline
        />
      </div>
    </td>
  )
}
export default TableCellCheckbox

TableCellCheckbox.defaultProps = {
  colspan: 1,
  rowspan: 1,
  mode: 'default'
}

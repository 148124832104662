import React from 'react'
import styles from './styles.module.css'

export interface Props {
  text: string
}

export const ErrorText: React.FC<Props> = ({ text }) => (
  <p className={styles.error_text}>{text}</p>
)

import React from 'react'

import { SeverityMild } from './components/SeverityMild'
import { SeverityModerate } from './components/SeverityModerate'
import { SeveritySevere } from './components/SeveritySevere'
import { SeverityVerySevere } from './components/SeverityVerySevere'

import styles from './styles.module.css'

export interface SeverityIconProps {
  severity: string
}

const severities = {
  none: 'NONE',
  moderate: 'MODERATE',
  mild: 'MILD',
  severe: 'SEVERE'
}

export const SeverityIcon: React.FC<SeverityIconProps> = ({ severity }) => {
  switch (severity.toUpperCase()) {
    case severities.none:
      return null
    case severities.mild:
      return <SeverityMild className={styles.severityIcon} />
    case severities.moderate:
      return <SeverityModerate className={styles.severityIcon} />
    case severities.severe:
      return <SeveritySevere className={styles.severityIcon} />
    default:
      return <SeverityVerySevere className={styles.severityIcon} />
  }
}

import React from 'react'
import { Icon } from '../Icon'
import styles from './styles.module.css'

export interface Props {
  text: string
  onDismiss?: (() => void) | null
  variant?: string
}

export const Chip: React.FC<Props> = ({ text, onDismiss = () => {} }) => (
  <div className={styles.chip}>
    <div className={styles.chip__item}>{text}</div>
    {onDismiss && (
      <span onClick={onDismiss}>
        <Icon glyph="times-circle" type="solid" className={styles.chip__icon} />
      </span>
    )}
  </div>
)

Chip.defaultProps = {
  onDismiss: () => {}
}

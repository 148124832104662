import React from 'react'
import {
  InferableComponentEnhancerWithProps,
  lifecycle,
  StateHandler,
  withHandlers,
  withState,
  ComponentEnhancer
} from 'recompose'
import classNames from 'classnames'
import { SelectFieldMenuOption } from '../SelectFieldMenuOption'
import styles from './styles.module.css'

interface Option<V = string | number> {
  value: V
  label: string
}

export interface Props {
  // type Option
  options?: Option[]
  initialFocusIndex?: number
  onChange?: (
    value: string | number,
    evt: React.SyntheticEvent,
    label: string
  ) => void
  onClickNoResults?: () => void
  closeMenu?: () => void
  showNoResults?: boolean
  customClassName?: string
}

export type ExtractTInner<T> = T extends InferableComponentEnhancerWithProps<
  infer TInner,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>
  ? TInner
  : never

const withFocusedState = withState<
  Props,
  number,
  'focusedIndex',
  'setFocusedIndex'
>('focusedIndex', 'setFocusedIndex', props => props.initialFocusIndex || 0)

type WithFocused = ExtractTInner<typeof withFocusedState> & Props
const withKeyDownHandler = withHandlers<
  ExtractTInner<typeof withFocusedState> & Props,
  { handleKeyDown: StateHandler<WithFocused> } & Props
>({
  handleKeyDown: ({
    options = [],
    focusedIndex,
    setFocusedIndex,
    onChange = () => {},
    closeMenu = () => {}
  }: WithFocused) => (evt: React.SyntheticEvent) => {
    const selectFocusedOption = () => {
      const selectedOption = options[focusedIndex]

      if (selectedOption && selectedOption.value) {
        onChange(selectedOption.value, evt, selectedOption.label)
      }
      closeMenu()
    }

    switch ((evt as React.KeyboardEvent).keyCode) {
      case 9: // tab
        selectFocusedOption()
        closeMenu()
        break
      case 13: // enter
        evt.stopPropagation()
        evt.preventDefault()
        selectFocusedOption()
        break
      case 27: // escape
        evt.stopPropagation()
        evt.preventDefault()
        closeMenu()
        break
      case 40: // arrow down
        evt.stopPropagation()
        evt.preventDefault()
        setFocusedIndex((focusedIndex + 1) % options.length)
        break
      case 38: // arrow up
        evt.stopPropagation()
        evt.preventDefault()
        setFocusedIndex(
          focusedIndex === 0 ? options.length - 1 : focusedIndex - 1
        )
        break
      default:
        break
    }

    return undefined
  }
})

type ComponentProps = ExtractTInner<typeof withKeyDownHandler> & Props

const withKeyDownListeners: ComponentEnhancer<
  ComponentProps,
  ComponentProps
> = lifecycle<ExtractTInner<typeof withKeyDownHandler> & Props, unknown>({
  componentDidMount() {
    const { handleKeyDown } = this.props
    document.addEventListener('keydown', handleKeyDown)
  },

  componentWillUnmount() {
    const { handleKeyDown } = this.props
    document.removeEventListener('keydown', handleKeyDown)
  }
})

const classes = (className?: string) => classNames(styles.menu, className)

export const SelectFieldMenu = withFocusedState(
  withKeyDownHandler(
    withKeyDownListeners(
      ({
        options = [],
        onChange = () => {},
        focusedIndex,
        customClassName,
        onClickNoResults = () => {},
        showNoResults
      }) => (
        <div className={classes(customClassName)}>
          {options.map((option, index) => (
            <SelectFieldMenuOption
              {...option}
              key={option.value}
              isSelected={index === focusedIndex}
              onClick={onChange}
            />
          ))}
          {showNoResults && options.length === 0 && (
            <SelectFieldMenuOption
              label="No results, try another search"
              value=""
              isSelected={false}
              onClick={onClickNoResults}
            />
          )}
        </div>
      )
    )
  )
)

SelectFieldMenu.displayName = 'SelectFieldMenu'

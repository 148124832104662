import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

export interface RadioButtonProps {
  name: string
  label: string
  id: string
  value: string
  checkedValue?: string
  disabled?: boolean
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  renderInline?: boolean
  className?: string
}

const classes = (renderInline?: boolean, className?: string) =>
  classNames(styles.container, { 'inline-block': renderInline }, className)

export const RadioButton: React.FC<RadioButtonProps> = ({
  name,
  label,
  id,
  value,
  checkedValue,
  disabled,
  handleChange,
  // Not sure what the original intent was, but renderInline has no effect.
  renderInline,
  className
}) => {
  return (
    <div className={classes(renderInline, className)}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        className={styles.radio}
        disabled={disabled}
        checked={checkedValue === value}
        onChange={handleChange}
      />
      <label className={styles.label} htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'

export interface Props<V = string | number> {
  isSelected?: boolean
  label: string
  lastOfGroup?: boolean
  onClick?: (
    value: V,
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>,
    label: string
  ) => void
  value: V
}

export const SelectFieldMenuOption: React.FC<Props> = ({
  isSelected = false,
  label,
  lastOfGroup = false,
  value,
  onClick = () => {}
}) => {
  const classes = classnames(styles.option, {
    [styles['option--selected']]: isSelected,
    [styles['option--divider']]: lastOfGroup
  })

  const handleClickEvent = (
    evt: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    onClick(value, evt, label)
  }

  return (
    <div className={classes} onClick={handleClickEvent}>
      {label}
    </div>
  )
}

import React from 'react'
import { Icon } from '../Icon'
import styles from './styles.module.css'

export type SortOrder = 'ascending' | 'descending' | 'none'

export interface Props {
  children?: string
  colspan?: number
  onClick?: (event: React.SyntheticEvent) => void
  rowspan?: number
  sortOrder?: SortOrder
  width?: number
}

export const TableHeaderCell: React.FC<Props> = ({
  children,
  colspan,
  onClick,
  rowspan,
  sortOrder,
  width
}) => {
  const isSorted: boolean = sortOrder
    ? ['ascending', 'descending'].includes(sortOrder)
    : false

  const sortIconGlyph: string =
    isSorted && sortOrder === 'ascending'
      ? 'long-arrow-alt-up'
      : 'long-arrow-alt-down'

  const onKeyDown = onClick
    ? (event: React.KeyboardEvent): void => {
        if (event.keyCode === 13) onClick(event)
      }
    : undefined

  return (
    <th
      className={styles.table_header_cell}
      role={onClick && 'button'}
      colSpan={colspan}
      rowSpan={rowspan}
      aria-sort={sortOrder}
      style={{ width }}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={onClick && 0}
    >
      <div
        className={styles.th_content}
        tabIndex={onClick && -1}
        role={onClick && 'buttonContent'}
      >
        {children}
        {isSorted && <Icon glyph={sortIconGlyph} />}
      </div>
    </th>
  )
}

TableHeaderCell.defaultProps = {
  colspan: 1,
  rowspan: 1,
  sortOrder: 'none'
}

import React from 'react'
import styles from './styles.module.css'

export interface Props {
  count: number
}

export const Counter: React.FC<Props> = ({ count }) => (
  <div className={styles.counter}>{count}</div>
)

import React, { Component } from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'
import { Icon } from '../Icon'
import { SelectFieldMenu } from '../SelectFieldMenu'
import { WithIsOpen } from '../../hocs/withIsOpen'

export type align = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'

export interface Props<V = string | number> {
  optionAlign?: '' | align
  disabled?: boolean
  onChange: (value: V) => void
  options: {
    value: V
    label: string
  }[]
}

export type LayoutProps = WithIsOpen & Props

export class Layout extends Component<LayoutProps, unknown> {
  private node: HTMLDivElement | null

  public constructor(props: LayoutProps) {
    super(props)
    this.node = null
    this.handleOptionSelect = this.handleOptionSelect.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  private handleOptionSelect(value: string | number) {
    const { onChange } = this.props
    onChange(value)
  }

  public handleDocumentClick(evt: Event) {
    const { setIsOpen } = this.props
    if (this.node && !this.node.contains(evt.target as HTMLElement)) {
      setIsOpen(false)
    }
  }

  public render() {
    const {
      disabled,
      isOpen,
      openAndCloseWithKeyboard,
      options,
      setIsOpen,
      toggleIsOpen,
      optionAlign
    } = this.props

    const iconGlyph = 'ellipsis-v'
    const wrapperClassNames = classnames(styles.wrapper, {
      [styles['wrapper--disabled']]: disabled
    })

    const menuClassName = classnames(styles.menu, {
      [styles['option--align_top_left']]: optionAlign === 'topLeft',
      [styles['option--align_top_right']]: optionAlign === 'topRight',
      [styles['option--align_bottom_left']]: optionAlign === 'bottomLeft',
      [styles['option--align_bottom_right']]:
        !optionAlign || optionAlign === 'bottomRight'
    })

    return (
      <div
        ref={node => {
          this.node = node
        }}
        className={wrapperClassNames}
        onClick={toggleIsOpen}
        onKeyDown={openAndCloseWithKeyboard}
        role="listbox"
        tabIndex={0}
      >
        <div className={styles.field}>
          <Icon className={styles.icon} glyph={iconGlyph} />
        </div>
        <div className={menuClassName}>
          {isOpen && (
            <SelectFieldMenu
              closeMenu={() => setIsOpen(false)}
              options={options}
              onChange={this.handleOptionSelect}
              customClassName={styles.fieldMenu}
            />
          )}
        </div>
      </div>
    )
  }
}

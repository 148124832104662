import React from 'react'
import styles from './styles.module.css'

export interface ToggleProps {
  domID?: string | undefined
  name?: string
  text?: string
  on: boolean
  onText?: string
  offText?: string
  onClick: (value: boolean) => void
  testID?: string | null
}

const handleClick = (onClick: (value: boolean) => void, on: boolean) =>
  onClick(!on)

export const Toggle: React.FC<ToggleProps> = ({
  domID = undefined,
  name = '',
  on = false,
  onClick = () => false,
  text = '',
  onText = 'On',
  offText = 'Off',
  testID = 'toggle'
}) => {
  const domIDs = {
    label: domID ? `${domID}-label` : undefined,
    toggleButton: domID ? `${domID}-toggleButton` : undefined,
    valueText: domID ? `${domID}-valueText` : undefined
  }

  const testIDs = {
    label: testID && `${testID}-label`,
    toggleButton: testID ? `${testID}-toggleButton` : null,
    valueText: testID ? `${testID}-valueText` : null
  }

  const sliderClassName = on ? 'slider' : 'slider--off'
  const pillClassName = on ? 'pill' : 'pill--off'
  const valueText = on ? onText : offText

  return (
    <div id={domID} className={styles.wrapper} data-testid={testID}>
      <label
        id={domIDs.label}
        htmlFor={name}
        className={styles.text}
        data-testid={testIDs.label}
      >
        {text}
      </label>
      <span
        id={domIDs.valueText}
        data-testid={testIDs.valueText}
        className={styles['value-text']}
      >
        {valueText}
      </span>
      <button
        id={domIDs.toggleButton}
        type="button"
        name={name}
        role="switch"
        aria-checked={on}
        className={styles['toggle-button']}
        onClick={() => handleClick(onClick, on)}
        data-testid={testIDs.toggleButton}
        data-jest="toggle"
      >
        <div className={styles[sliderClassName]} />
        <div className={styles[pillClassName]} />
      </button>
    </div>
  )
}

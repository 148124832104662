import React from 'react'
import styles from './styles.module.css'
import { FilterableInputWithOptions } from '../FilterableInputWithOptions'
import { GridColumn } from '../GridColumn'
import { Icon } from '../Icon'

export interface Props {
  errors?: {
    textMessageNumber?: string
  }
  optedOutNumbers: string[]
  phoneNumbers: string[]
  renderErrors: (message: string) => React.ReactNode
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  includeTextMessage: boolean
  values: {
    textMessageNumber: string
  }
}

const renderTextMsgWarning = (
  <GridColumn span={4}>
    <div className={styles.textWarning}>
      <Icon glyph="mobile-alt" className={styles.mobileIcon} />
      <h6 className={styles.mobileText} data-jest="txtMsgWarning">
        Please make sure the patient has a smartphone capable of receiving text
        messages and accessing websites.
      </h6>
    </div>
  </GridColumn>
)

const renderOptedOutWarning = (
  <GridColumn span={4}>
    <div className={styles.textWarning}>
      <Icon
        glyph="exclamation"
        type="solid"
        className={styles.optInAlertIcon}
      />
      <h6 className={styles.optInAlertText} data-jest="optInWarning">
        This number opted out of receiving messages from Navigating Care.
      </h6>
    </div>
  </GridColumn>
)

const renderTextWarning = (
  values: { textMessageNumber: string },
  optedOutNumbers: string[]
) => {
  if (optedOutNumbers.includes(values.textMessageNumber)) {
    return renderOptedOutWarning
  }
  return renderTextMsgWarning
}

export const PhoneNumberPicker: React.FC<Props> = ({
  errors = {},
  includeTextMessage,
  optedOutNumbers,
  phoneNumbers,
  renderErrors,
  setFieldValue,
  values
}) => (
  <>
    <GridColumn span={4}>
      <FilterableInputWithOptions
        disabled={!includeTextMessage}
        error={!!errors.textMessageNumber}
        mode="phone"
        name="textMessageNumber"
        onChange={setFieldValue}
        options={phoneNumbers}
        placeholder={values.textMessageNumber}
        value={values.textMessageNumber}
      />
      {includeTextMessage &&
        errors.textMessageNumber &&
        renderErrors(errors.textMessageNumber)}
    </GridColumn>
    {includeTextMessage && renderTextWarning(values, optedOutNumbers)}
  </>
)

import React from 'react'
import styles from './styles.module.css'
import { Icon, Props as IconProps } from '../Icon'
import { Paragraph } from '../Paragraph'
import colors from '../../css/colors.module.css'

export interface Props {
  text: string
  icon?: IconProps
  maxWidth?: number
}

export const Notice: React.FC<Props> = ({ text, icon, maxWidth = 352 }) => (
  <div className={styles.notice} style={{ maxWidth }}>
    <div>
      {icon && <Icon className={styles.notice__icon} type="solid" {...icon} />}
    </div>
    <div>
      <Paragraph variant="meta" className={styles.notice__text}>
        {text}
      </Paragraph>
    </div>
  </div>
)

export const AlertNotice: React.FC<Props> = ({
  text,
  maxWidth = undefined
}) => (
  <Notice
    text={text}
    maxWidth={maxWidth}
    icon={{ color: colors.red, glyph: 'exclamation' }}
  />
)

export const InfoNotice: React.FC<Props> = ({ text, maxWidth = undefined }) => (
  <Notice
    text={text}
    maxWidth={maxWidth}
    icon={{ color: colors.grey, glyph: 'info' }}
  />
)

export const SuccessNotice: React.FC<Props> = ({
  text,
  maxWidth = undefined
}) => (
  <Notice
    text={text}
    maxWidth={maxWidth}
    icon={{ color: colors['medium-green'], glyph: 'check' }}
  />
)

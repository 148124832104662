import React, { useState, useEffect, useRef } from 'react'
import Select, { components } from 'react-select'
import { Icon } from '../Icon'
import selectStyles from './selectStyles'
import colors from '../../css/colors.module.css'
import styles from './styles.module.css'

/**
 * Based on the React-Select package
 * Documentation can be found at https://react-select.com/
 * Uses the Style API from React-Select https://react-select.com/styles
 * */

export interface SelectFieldProps<V = string | number | (string | number)[]> {
  clearInputOnFocus?: boolean // Use this prop to add AutoClearingFilterableSelectField behavior
  currentValue?: any
  disabled?: boolean
  hasError?: boolean
  name: string
  onChange: (name: string, value: any) => void
  icon?: string // Adding a glyph name inserts an icon on left side of input e.g. 'search'
  isMulti?: boolean
  options:
    | {
        value: V
        label: string
      }[]
    | any
  placeholder?: string
  openMenuOnFocus?: boolean
  isSearchable?: boolean
  isClearable?: boolean
  onFocus?: (evt: React.FocusEvent) => void
  onBlur?: (evt: React.FocusEvent) => void
  onMouseEnter?: (evt: React.MouseEvent) => void
  onMouseLeave?: (evt: React.MouseEvent) => void
  closeMenuOnSelect?: boolean
  blurInputOnSelect?: boolean
  noOptionsMessage?: string | null
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  toggleIsOpen?: () => void
  filterOption?: (options: any, inputValue: string) => any
  iconClosed?: string
  iconOpen?: string
}

export const Layout: React.FC<SelectFieldProps> = ({
  clearInputOnFocus = false,
  currentValue,
  disabled,
  hasError,
  icon = '',
  isMulti = false,
  name,
  onChange,
  options,
  placeholder,
  openMenuOnFocus = true,
  isSearchable = true,
  isClearable = false,
  onFocus = () => {},
  onBlur = () => {},
  onMouseEnter,
  onMouseLeave,
  isOpen = false,
  setIsOpen = () => {},
  toggleIsOpen = () => {},
  closeMenuOnSelect = true,
  blurInputOnSelect = false,
  noOptionsMessage = 'No results, try another search',
  filterOption,
  iconOpen = 'chevron-up',
  iconClosed = 'chevron-down'
}) => {
  // eslint-disable-next-line no-console
  console.warn(
    'setIsOpen and toggleIsOpen props are deprecated and will be removed when their corresponding components are removed'
  )

  const [value, setValue] = useState(null)
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen)
  const getDefaultValues = () => {
    // to handle clearInputOnFocus, if value is already passed from parent component
    if (clearInputOnFocus && isMenuOpen) return ''

    // Easy case. Just pick the option out of the list of options
    if (!isMulti) {
      return options.filter((o: any) => o.value === currentValue)
    }

    // Harder case. Not only do we need to return the selected
    // options, we also need to return them in the order they
    // were specified.
    return options
      .filter(
        (o: any) =>
          currentValue &&
          currentValue instanceof Array &&
          currentValue.some((i: any) => i === o.value)
      )
      .sort((a: any, b: any) =>
        currentValue.indexOf(a.value) > currentValue.indexOf(b.value) ? 1 : -1
      )
  }

  const selectRef: any = useRef(null)
  useEffect(() => setValue(getDefaultValues()), [currentValue, options])

  const toggleOpen = (menuState: boolean) => {
    if (clearInputOnFocus && menuState) {
      setValue(null)
    }
    setIsMenuOpen(menuState)
    setIsOpen(menuState)
    toggleIsOpen()
  }

  const handleOptionSelect = (option: any) => {
    let selectedValue: any = isMulti ? [] : null

    if (option) {
      selectedValue = isMulti
        ? option.map((i: { value: string }) => i.value)
        : option.value
    }
    setValue(option || selectedValue)
    onChange(name, selectedValue)
    setTimeout(() => {
      if (selectRef && selectRef.current !== null) {
        selectRef.current.focus()
      }
    }, 100)
  }

  const DropdownIndicator = (indicatorProps: any) => 
    <components.DropdownIndicator {...indicatorProps}>
      {!isSearchable && <Icon glyph={isMenuOpen ? iconOpen : iconClosed} />}
    </components.DropdownIndicator>
  

  const MultiValueRemove = (multiValueRemoveProps: any) => 
    <components.MultiValueRemove {...multiValueRemoveProps}>
      <Icon glyph="times-circle" type="solid" />
    </components.MultiValueRemove>
  

  const ValueContainer = (valueContainerProps: any) => {
    const { children } = valueContainerProps
    return (
      <components.ValueContainer {...valueContainerProps}>
        <Icon glyph={icon} color={colors.grey} className={styles.selectIcon} />
        {children}
      </components.ValueContainer>
    )
  }

  const customComponents = icon
    ? { DropdownIndicator, MultiValueRemove, ValueContainer }
    : { DropdownIndicator, MultiValueRemove }

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!isMenuOpen) {
        toggleOpen(true)
      }
    }
  }

  return (
    <div
      data-testid="select-component"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDown={onKeyPress}
      data-selector={name}
    >
      <Select
        ref={selectRef}
        role="listbox"
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => toggleOpen(true)}
        onMenuClose={() => toggleOpen(false)}
        components={customComponents}
        classNamePrefix="select"
        isDisabled={disabled}
        isMulti={isMulti}
        maxMenuHeight={225}
        name={name}
        inputId={name}
        onFocus={onFocus}
        onChange={(option) => handleOptionSelect(option)}
        options={options}
        placeholder={placeholder}
        styles={selectStyles(!!hasError)}
        value={value || getDefaultValues()}
        openMenuOnFocus={openMenuOnFocus}
        isSearchable={isSearchable}
        isClearable={isClearable}
        closeMenuOnSelect={closeMenuOnSelect}
        blurInputOnSelect={blurInputOnSelect}
        noOptionsMessage={() => noOptionsMessage}
        onBlur={onBlur}
        filterOption={filterOption}
        // When the munu opens on focus then it's way too easy
        // for the user to accidentalyl select the first option
        // in the list while quickly moving through the form.
        // TODO: Maybe use `false` in all cases?
        tabSelectsValue={!openMenuOnFocus}
      />
    </div>
  )
}

export default Layout

import React, { Component } from 'react'
import { SelectFieldMenu } from '../SelectFieldMenu'
import { TextInput } from '../TextInput'
import styles from './styles.module.css'
import { WithIsOpen } from '../../hocs/withIsOpen'

interface Option {
  value: string
  label: string
}

export interface Props {
  defaultOptions: Option[]
  delay?: number
  disabled?: boolean
  name: string
  onChange: (name: string, value: string | number) => void
  optionsService: (query: string) => Promise<string>
  parseOptions: (opt: string) => Option[]
  placeholder?: string
  value: string
}

export type LayoutProps = Props & WithIsOpen

interface State {
  options: Option[]
  filterText: string
}

export class Layout extends Component<LayoutProps, State> {
  private node: HTMLDivElement | null

  private performSearchTimeout: NodeJS.Timeout | null

  public static defaultProps = {
    defaultOptions: [],
    delay: 100,
    disabled: false,
    onChange: () => {},
    placeholder: 'Select One...',
    value: ''
  }

  public constructor(props: LayoutProps) {
    super(props)
    this.node = null
    this.performSearchTimeout = null
    this.state = {
      options: props.defaultOptions,
      filterText: ''
    }
    this.handleOptionSelect = this.handleOptionSelect.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleFilterTextChange = this.handleFilterTextChange.bind(this)
    this.openMenu = this.openMenu.bind(this)
  }

  public componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick)
  }

  public componentDidUpdate(_: Props, prevState: State) {
    const { filterText } = this.state
    if (filterText === prevState.filterText) {
      // Value hasn't changed, so there's nothing to do here
    } else if (filterText.length >= 3) {
      this.performSearch(filterText)
    } else {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ options: [] })
    }
  }

  public componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick)
  }

  private handleOptionSelect(value: string | number) {
    const { name, onChange, setIsOpen } = this.props
    onChange(name, value)
    setIsOpen(false)
  }

  private handleDocumentClick(evt: Event) {
    const { setIsOpen } = this.props
    if (this.node && !this.node.contains(evt.target as HTMLElement)) {
      setIsOpen(false)
    }
  }

  private handleFilterTextChange(evt: React.FormEvent<HTMLInputElement>) {
    this.setState({ filterText: (evt.target as HTMLInputElement).value })
  }

  private openMenu() {
    const { disabled, isOpen, setIsOpen } = this.props
    if (!disabled && !isOpen) {
      setIsOpen(true)
      this.setState({
        filterText: ''
      })
    }
  }

  private performSearch(query: string) {
    // Wait some time before actually performing the search. If search
    // is triggered again within that time period, cancel the original search.
    if (this.performSearchTimeout) {
      clearTimeout(this.performSearchTimeout)
    }

    const { delay, optionsService } = this.props
    this.performSearchTimeout = setTimeout(() => {
      optionsService(query).then(res => {
        this.processResponse(res)
      })
    }, delay)
  }

  private processResponse(res: string) {
    const { parseOptions } = this.props
    try {
      this.setState({ options: parseOptions(res) })
    } catch (err) {
      this.setState({ options: [] })
    }
  }

  public render() {
    const { filterText, options } = this.state
    const {
      disabled,
      isOpen,
      name,
      openAndCloseWithKeyboard,
      placeholder,
      setIsOpen,
      value
    } = this.props
    const chosen = options.find(option => `${option.value}` === value)
    const containerText = !isOpen && chosen ? chosen.label : filterText
    const menu =
      isOpen && options.length ? (
        <SelectFieldMenu
          closeMenu={() => setIsOpen(false)}
          options={options}
          onChange={this.handleOptionSelect}
        />
      ) : null

    return (
      <div
        ref={node => {
          this.node = node
        }}
        className={styles.field}
        onClick={this.openMenu}
        onKeyDown={openAndCloseWithKeyboard}
      >
        <TextInput
          disabled={disabled}
          placeholder={placeholder}
          value={containerText}
          glyph="search"
          glyphAlign="left"
          onChange={this.handleFilterTextChange}
        />
        <div className={styles.menu}>{menu}</div>
        <input type="hidden" name={name} value={value} />
      </div>
    )
  }
}

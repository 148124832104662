import React from 'react'
import classnames from 'classnames'
import styles from './styles.module.css'

export interface LabelProps {
  htmlFor?: string
  variant?: 'body' | 'default' | 'meta' | 'supporting'
}

export const Label: React.FC<LabelProps> = ({
  children,
  htmlFor,
  variant = 'default'
}) => {
  const labelClass = classnames({
    [styles.label]: true,
    [styles[`label--${variant}`]]: true
  })

  return (
    <label className={labelClass} htmlFor={htmlFor}>
      {children}
    </label>
  )
}

import React from 'react'
import { SelectField } from '../SelectField'
import { GlyphAlign } from '../Icon'

interface Option<V = string | number> {
  value: V
  label: string
  search?: string
}

export interface Props {
  disabled?: boolean
  iconAlign?: GlyphAlign
  iconClosed?: string
  iconOpen?: string
  icon?: string
  name: string
  onChange: (name: string, value: string | number) => void
  onBlur?: (event: React.FormEvent) => void
  options: Option[]
  placeholder?: string
  showNoResults?: boolean
  value?: string | number
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  toggleIsOpen?: () => void
}

export const Layout: React.SFC<Props> = ({
  disabled = false,
  iconAlign = 'right',
  iconClosed = 'chevron-down',
  iconOpen = 'chevron-up',
  isOpen,
  name,
  options = [],
  placeholder = 'Select One...',
  setIsOpen,
  showNoResults = true,
  toggleIsOpen,
  value,
  onBlur,
  onChange,
  icon
}) => {
  // eslint-disable-next-line no-console
  console.warn(
    'The FilterableSelectField component is deprecated and will be removed in a future version of the react_components library. Use the SelectField component instead.'
  )

  const filteredOptions = (option: Option, searchText: string) => {
    if (!searchText) return true
    const { label } = option
    return label.toLowerCase().includes(searchText.toLowerCase())
  }

  let iconValue = icon
  if (!iconValue && iconAlign === 'left') {
    iconValue = iconOpen || iconClosed
  }

  return (
    <SelectField
      icon={iconValue}
      iconOpen={iconAlign === 'right' ? iconOpen : undefined}
      iconClosed={iconAlign === 'right' ? iconClosed : undefined}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      toggleIsOpen={toggleIsOpen}
      currentValue={value}
      name={name}
      placeholder={placeholder}
      clearInputOnFocus={false}
      disabled={disabled}
      isMulti={false}
      options={options}
      onChange={onChange}
      isSearchable
      isClearable={false}
      openMenuOnFocus={false}
      onBlur={onBlur}
      filterOption={filteredOptions}
      noOptionsMessage={showNoResults ? 'No results, try another search' : null}
    />
  )
}

import React, { useState } from 'react'

import { Button, ButtonProps } from '../Button'
import { Modal, Props as ModalProps } from '../Modal'

/**
 * A ModalButton opens and closes it's own Modal
 */
export const ModalButton: React.FC<ModalButtonProps> = props => {
  const { label, children, buttonProps, modalProps } = props

  const [isOpen, setIsOpen] = useState(false)
  const onClick = (event: React.SyntheticEvent): void => {
    setIsOpen(true)
    if (buttonProps && buttonProps.onClick) {
      buttonProps.onClick(event)
    }
  }
  const onCloseAttempt = (): void => {
    setIsOpen(false)
    if (modalProps && modalProps.onCloseAttempt) {
      modalProps.onCloseAttempt()
    }
  }
  return (
    <>
      <Button {...buttonProps} onClick={onClick}>
        {label}
      </Button>
      {isOpen && (
        <Modal {...modalProps} onCloseAttempt={onCloseAttempt}>
          {children}
        </Modal>
      )}
    </>
  )
}

export interface ModalButtonProps {
  label: string
  buttonProps?: ButtonProps
  modalProps?: ModalProps
}

export default ModalButton

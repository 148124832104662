import React from 'react'
import classnames from 'classnames'

import styles from './styles.module.css'

export interface IndicatorProps {
  variant: 'ok' | 'alert' | 'allgood'
  message: string
}

export const Indicator: React.FC<IndicatorProps> = ({ message, variant }) => {
  const indicatorClass = classnames({
    [styles.indicator]: true,
    [styles[`indicator--${variant}`]]: true
  })
  return (
    <div className={indicatorClass}>
      <span>{message}</span>
    </div>
  )
}

import colors from '../../css/colors.module.css'
import zIndex from '../../css/zindex.module.css'

const border = {
  green: `1px solid ${colors['medium-green']}`,
  lightGrey: `1px solid ${colors['light-grey']}`,
  grey: `1px solid ${colors.grey}`,
  red: `1px solid ${colors.red}`
}

// Uses the Style API from React-Select https://react-select.com/styles
// FIXME: Type `any` used here pending compatibility with StylesConfig<OptionType, IsMulti, GroupType>
// see also: https://stackoverflow.com/questions/63696310/how-to-use-typescript-with-the-definition-of-custom-styles-for-a-select-using-re
const selectStyles: any = (hasError: boolean) => ({
  container: (base: any) => ({
    ...base,
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
    textAlign: 'left'
  }),
  control: (
    base: any,
    state: {
      isFocused: boolean
      isMulti: boolean
      isDisabled: boolean
      selectProps: any
    }
  ) => ({
    ...base,
    textIndent: `${state.isMulti ? '6px' : '0'}`,
    fontFamily: "'Open Sans', sans-serif",
    border: hasError
      ? border.red
      : state.isFocused
      ? border.green
      : border.lightGrey,
    borderRadius: 3,
    backgroundColor: state.isDisabled ? colors['off-white'] : colors.white,
    boxShadow: 'none',
    '&:hover': {
      border: state.isFocused
        ? border.green
        : hasError
        ? border.red
        : border.grey
    },
    '&:focused': {
      border: hasError ? border.red : border.green
    },
    cursor: state.selectProps.isSearchable ? 'text' : 'pointer'
  }),
  dropdownIndicator: (base: any, state: { selectProps: any }) => ({
    ...base,
    color: colors.grey,
    padding: '10px 16px',
    display: 'flex',
    alignSelf: 'center',
    opacity: state.selectProps.components.ValueContainer && 0
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: 42
  }),
  indicatorSeparator: (base: any) => ({ ...base, display: 'none' }),
  multiValueLabel: (base: any, state: { isDisabled: boolean }) => ({
    ...base,
    alignSelf: 'center',
    color: !state.isDisabled ? colors.black : colors.grey,
    fontSize: 16
  }),
  multiValue: (base: any, state: { selectProps: any }) => ({
    ...base,
    color: colors.black,
    backgroundColor: colors['off-white'],
    borderRadius: 0,
    height: 32,
    ':nth-of-type(1)': {
      marginLeft: state.selectProps.components.ValueContainer && 12
    }
  }),
  multiValueRemove: (base: any, state: { isFocused: any }) => ({
    ...base,
    color: colors['primary-green'],
    padding: '7px 10px 7px 0px',
    backgroundColor: state.isFocused && colors['very-light-grey'],
    '&:hover': {
      backgroundColor: colors['off-white'],
      color: colors['primary-green'],
      cursor: 'pointer'
    }
  }),
  option: (provided: any, state: { isSelected: any; isFocused: any }) => ({
    ...provided,
    color: colors['primary-green'],
    backgroundColor: state.isSelected
      ? colors['very-light-grey']
      : state.isFocused
      ? colors['off-white']
      : colors.white,
    padding: '13px 20px',
    ':active': {
      backgroundColor: 'none'
    },
    cursor: 'pointer'
  }),
  placeholder: (base: any, state: { isFocused: any; selectProps: any }) => ({
    ...base,
    paddingLeft: state.selectProps.components.ValueContainer ? 26 : 6,
    color: colors['light-grey']
  }),
  singleValue: (
    base: any,
    state: { selectProps: any; isDisabled: boolean }
  ) => ({
    ...base,
    color: !state.isDisabled ? colors.black : colors.grey,
    paddingLeft: state.selectProps.components.ValueContainer && 26
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: 0,
    boxShadow: 'none',
    border: `1px solid ${colors['light-grey']}`,
    borderTop: 'none',
    borderRadius: '0 0 3px 3px',
    zIndex: zIndex['z-index-top']
  }),
  valueContainer: (
    base: any,
    state: { isMulti: boolean; isDisabled: boolean }
  ) => {
    return {
      ...base,
      alignContent: 'center',
      alignSelf: 'center',
      color: !state.isDisabled ? colors.black : colors['light-grey'],
      overflow: 'visible',
      padding: 3,
      paddingLeft: !state.isMulti && 12
    }
  }
})

export default selectStyles

import React from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'

export interface Props {
  span?: number
  children?: React.ReactNode
  className?: string
  testId?: string
}

export const GridColumn: React.FC<Props> = ({ children, span, className, testId }) => {
  const classes = classNames(
    styles.grid_column,
    styles[`grid_column--${span}`],
    className
  )
  return <div data-test-id={testId} className={classes}>{children}</div>
}

GridColumn.defaultProps = {
  span: 1,
  children: ''
}

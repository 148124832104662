export const formatPhoneNumber = (rawPhone: string) => {
  const phone = rawPhone.trim()
  const head = phone.slice(0, 3)
  const middle = phone.slice(3, 6)
  const tail = phone.slice(6)

  let newValue = head

  if (middle !== '') {
    newValue = newValue.concat('-', middle)
  }

  if (tail !== '') {
    newValue = newValue.concat('-', tail)
  }

  return newValue
}

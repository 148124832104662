import React from 'react'
import classNames from 'classnames'
import { Button } from '../Button'
import { Icon } from '../Icon'
import styles from './styles.module.css'

export interface Props {
  onCloseButtonAttempt: () => void
  containerClass?: string
  sneezeguard: boolean
}

const escapeKeyCode = 27

export class SidePanel extends React.Component<Props> {
  public static defaultProps = {
    sneezeguard: true
  }

  public componentDidMount() {
    const { onCloseButtonAttempt } = this.props
    document.addEventListener('keydown', e => {
      if (e.keyCode === escapeKeyCode && !e.defaultPrevented) {
        onCloseButtonAttempt()
      }
    })
  }

  public componentWillUnmount() {
    const { onCloseButtonAttempt } = this.props
    document.removeEventListener('keydown', e => {
      if (e.keyCode === escapeKeyCode && !e.defaultPrevented) {
        onCloseButtonAttempt()
      }
    })
  }

  public render() {
    const {
      children,
      onCloseButtonAttempt,
      containerClass,
      sneezeguard
    } = this.props
    return (
      <div className={styles.sidePanel}>
        {sneezeguard && <div className={styles.sneezeguard} />}
        <div className={classNames(styles.contentContainer, containerClass)}>
          <header className={styles.header}>
            <div className={styles.close}>
              <Button variant="tertiary" onClick={onCloseButtonAttempt} flush>
                <span className={styles.icon}>
                  <Icon glyph="times" />
                </span>
              </Button>
            </div>
          </header>
          {children}
        </div>
      </div>
    )
  }
}

import React from 'react'
import { Button, Variant } from '../Button'
import { Icon } from '../Icon'

export interface Props {
  domId?: string | undefined
  dataTestId?: string | undefined
  text?: string
  isComplete?: boolean
  isSubmitting?: boolean
  isValid?: boolean
  onSubmit?: () => void
}

const buttonState = (
  isComplete: boolean,
  isSubmitting: boolean,
  isValid: boolean
) => {
  if (isComplete) {
    return 'complete'
  }

  if (isSubmitting) {
    return 'submitting'
  }

  if (isValid) {
    return 'enabled'
  }

  return 'disabled'
}

export const SubmitButton: React.FC<Props> = ({
  domId,
  dataTestId,
  onSubmit = () => {},
  text = '',
  isComplete = false,
  isSubmitting = false,
  isValid = false
}) => {
  let disabled
  let variant = 'primary'
  let buttonText

  switch (buttonState(isComplete, isSubmitting, isValid)) {
    case 'submitting':
      disabled = true
      variant = 'primary'
      buttonText = <Icon glyph="spinner" spin />
      break
    case 'complete':
      disabled = true
      variant = 'success'
      buttonText = <Icon glyph="check-circle" type="solid" />
      break
    case 'enabled':
      disabled = false
      variant = 'primary'
      buttonText = text
      break
    default:
      // disabled
      disabled = true
      variant = 'primary'
      buttonText = text
      break
  }

  return (
    <Button
      domId={domId}
      dataTestId={dataTestId}
      disabled={disabled}
      onClick={onSubmit}
      variant={variant as Variant}
    >
      {buttonText}
    </Button>
  )
}

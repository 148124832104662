import React from 'react'
import { SelectField } from '../SelectField'

export interface Props<V = string | number> {
  disabled?: boolean
  name: string
  onChange: (name: string, value: V) => void
  options: {
    value: V
    label: string
  }[]
  placeholder?: string
  value: V
  onFocus?: (evt: React.FocusEvent) => void
  onBlur?: (evt: React.FocusEvent) => void
  onMouseEnter?: (evt: React.MouseEvent) => void
  onMouseLeave?: (evt: React.MouseEvent) => void
  isOpen?: boolean
  setIsOpen?: () => void
  toggleIsOpen?: () => void
}

export const Layout: React.SFC<Props> = ({
  isOpen,
  setIsOpen,
  toggleIsOpen,
  disabled,
  name,
  options,
  placeholder = 'Select One',
  value,
  onFocus,
  onBlur,
  onMouseEnter,
  onMouseLeave,
  onChange
}) => {
  // eslint-disable-next-line no-console
  console.warn(
    'The SingleSelectField component is deprecated and will be removed in a future version of the react_components library. Use the SelectField component instead.'
  )
  return (
    <SelectField
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      toggleIsOpen={toggleIsOpen}
      currentValue={value}
      name={name}
      placeholder={placeholder}
      clearInputOnFocus={false}
      disabled={disabled}
      isMulti={false}
      options={options}
      onChange={onChange}
      isSearchable={false}
      isClearable={false}
      openMenuOnFocus={false}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  )
}

import React from 'react'
import { SelectField } from '../SelectField'

interface Option {
  value: string | number
  label: string
}

export interface Props {
  disabled?: boolean
  name: string
  onChange: (name: string, values: (string | number)[]) => void
  options: Option[]
  placeholder?: string
  values: (string | number)[]
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  toggleIsOpen?: () => void
}

export const Layout: React.SFC<Props> = ({
  disabled = false,
  name,
  isOpen,
  options = [],
  placeholder = 'Select',
  setIsOpen,
  toggleIsOpen,
  values = [],
  onChange
}) => {
  // eslint-disable-next-line no-console
  console.warn(
    'The MultiSelectField component is deprecated and will be removed in a future version of the react_components library. Use the SelectField component instead.'
  )
  return (
    <SelectField
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      toggleIsOpen={toggleIsOpen}
      currentValue={values}
      name={name}
      placeholder={placeholder}
      clearInputOnFocus={false}
      disabled={disabled}
      isMulti
      options={options}
      onChange={onChange}
      isSearchable={false}
      isClearable={false}
      openMenuOnFocus={false}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      noOptionsMessage={null}
    />
  )
}

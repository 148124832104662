import React from 'react'
import styles from './styles.module.css'

export interface Props {
  children: React.ReactNode
}

export const TableRow: React.FC<Props> = ({ children }) => (
  <tr className={styles.table_row}>{children}</tr>
)

import React, { ReactNode } from 'react'
import styles from './styles.module.css'

export interface Props {
  selected?: boolean
  onClick: (event: React.MouseEvent) => void
  children: ReactNode
}

export const TabNavItem: React.FC<Props> = ({
  selected = false,
  onClick,
  children
}) => {
  const className = selected ? styles.selectedTab : styles.tab

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  )
}

import React from 'react'
import { Symptom } from '../../types/healthTracker'

import styles from './styles.module.css'

/**
 * NOTE: This component is unfinished, but added to the library
 * to prevent duplicate work after higher priority work is completed.
 * It is intended to be used on Health Tracker workboards.
 */

const mapWords = (title: string) => {
  let mappedTitle = title

  const mapToWords: { [index: string]: string } = {
    abdominalpain: 'abdominal pain',
    blurredvision: 'blurred vision',
    generalpain: 'general pain',
    jointpain: 'joint pain',
    mouthorthroatsores: 'mouth or throat sores',
    musclepain: 'muscle pain',
    numbnessortingling: 'numbness or tingling',
    shortnessofbreath: 'shortness of breath',
    urinaryproblems: 'urinary problems',
    None: 'No',
  }

  Object.keys(mapToWords).forEach((squishedWord) => {
    mappedTitle = mappedTitle.replace(squishedWord, mapToWords[squishedWord])
  })

  return mappedTitle
}

const formatType = (symptomType: string) =>
  symptomType.charAt(0).toUpperCase() + symptomType.slice(1)

const formatTitle = (
  title: string | undefined,
  type: string,
  rawSeverity: string | undefined
) => {
  if (!title && type) return formatType(type)

  const baseTitle = title && mapWords(title)

  if (type !== 'pain' || !rawSeverity || Number.isNaN(Number(rawSeverity))) {
    return baseTitle
  }

  return `${baseTitle} (${rawSeverity})`
}

export interface SymptomTitleProps {
  symptom: Symptom
}

export const SymptomTitle: React.FC<SymptomTitleProps> = ({ symptom }) => (
  <p className={styles.statusLabel}>
    {formatTitle(symptom.title, symptom.symptomType, symptom.rawSeverity)}
  </p>
)

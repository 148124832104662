import React, { Component } from 'react'
import Filters from './Filters'
import FilterOptions from './FilterOptions'
import { FilterProps } from './Filter.interface'

export interface Props {
  onDismiss?: (() => void) | null
  onApply?: ((values: any) => void) | null
  onClearAll?: (() => void) | null
  onRemove?: (() => void) | null
  showRefresh?: boolean
  filters: FilterProps[]
  // remember to make it false after opening the panel otherwise it will effect isHidden state.
  openPanel?: boolean
}

export interface State {
  isHidden: boolean
  filterData: FilterProps[]
  previousFilterData: FilterProps[]
  lastRefresh: Date
}

export class FilterPanelV2 extends Component<Props, State> {
  public static defaultProps = {
    onApply: () => {},
    onDismiss: () => {},
    onClearAll: () => {},
    onRemove: () => {},
    showRefresh: true,
    openPanel: false
  }

  public constructor(props: Props) {
    super(props)
    this.state = {
      isHidden: !props.openPanel,
      lastRefresh: new Date(Date.now()),
      filterData: [...props.filters],
      previousFilterData: [...props.filters]
    }
  }

  public static getDerivedStateFromProps(props: Props, currentState: State) {
    if (props.openPanel) {
      return {
        ...currentState,
        isHidden: !props.openPanel
      }
    }
    return null
  }

  public toggleHidden = (isHidden: boolean) => {
    this.setState({ isHidden })
  }

  public updateFilterData = (filterData: FilterProps[]) => {
    this.setState({
      filterData: JSON.parse(JSON.stringify(filterData))
    })
  }

  public togglePanel = () => {
    const { isHidden, previousFilterData } = this.state
    const { onDismiss } = this.props
    if (!isHidden) {
      this.updateFilterData(previousFilterData)
    }
    this.toggleHidden(!isHidden)
    if (onDismiss) {
      onDismiss()
    }
  }

  public removeFilter = (name: string, index: number) => {
    const { onRemove } = this.props
    this.setState((prevState) => {
      const filterData = JSON.parse(JSON.stringify(prevState.filterData))
      const filter: FilterProps = filterData.find(
        (item: FilterProps) => item.name === name
      )
      if (filter) {
        if (filter.selectedValues instanceof Array) {
          filter.selectedValues.splice(index, 1)
        } else {
          filter.selectedValues = undefined
        }
      }
      return { filterData }
    })

    this.toggleHidden(false)
    if (onRemove) {
      onRemove()
    }
  }

  public onSubmit = (values: any) => {
    const { onApply } = this.props
    const { filterData } = this.state
    const filters = [...filterData]
    Object.keys(values).forEach((key) => {
      const filter = filters.find((item) => item.name === key)
      if (filter) {
        filter.selectedValues = values[key]
      }
    })
    this.setState({
      filterData: [...filters],
      previousFilterData: JSON.parse(JSON.stringify(filters))
    })
    this.toggleHidden(true)
    if (onApply) {
      onApply(values)
    }
  }

  public onClearAll = () => {
    const { onClearAll } = this.props
    const { filterData } = this.state
    const filters = [...filterData]
    filters.map((filter) => {
      const filterObj = filter
      delete filterObj.selectedValues
      return filterObj
    })
    this.setState({
      filterData: [...filters],
      previousFilterData: JSON.parse(JSON.stringify(filters))
    })
    this.toggleHidden(true)
    if (onClearAll) {
      onClearAll()
    }
  }

  public selectedValues = () => {
    const values: any = {}
    const { filterData } = this.state
    filterData.forEach((filter) => {
      if (filter.selectedValues) {
        values[filter.name] = filter.selectedValues
      }
    })
    return values
  }

  public render() {
    const { isHidden, filterData, lastRefresh } = this.state
    const { showRefresh } = this.props
    return (
      <>
        <Filters
          isHidden={isHidden}
          removeFilter={this.removeFilter}
          filterData={filterData}
          onToggle={this.togglePanel}
          lastRefresh={lastRefresh}
          showRefresh={showRefresh}
          onRefresh={() => {
            this.onSubmit(this.selectedValues())
          }}
        />
        {!isHidden && 
          <FilterOptions
            {...this.props}
            filterData={filterData}
            onClearAll={this.onClearAll}
            onSubmit={this.onSubmit}
          />
        }
      </>
    )
  }
}
